import React from 'react';
import Action from './components/FormAction';

const FormActions = (props) => {
  return (
    <div
      className={
        props.breakpoint
          ? `row g-3 flex-${props.breakpoint}-row-reverse`
          : 'row g-3'
      }
    >
      {props.actions &&
        props.actions.map((action, index) => (
          <Action key={index} breakpoint={props.breakpoint} {...action} />
        ))}
    </div>
  );
};

export default FormActions;

import React from "react";
import FilterCheckbox from "./components/FilterCheckbox";
import FilterDate from "./components/FilterDate";
import FilterVenue from "./components/FilterVenue";
import FilterPill from "./components/FilterPill";

import { utcToLocal } from "../../../../utils/utc";

const Filters = (props) => {
  function applyClickHandler() {
    if (props.onChange) {
      const activeFilters = props.filters
        .map((filter) => filter.ref.current.getActiveFilters())
        .flat();
      console.log(activeFilters);
      props.onChange(activeFilters);
    }
  }

  function clearClickHandler() {
    if (props.onChange) {
      const activeFilters = props.filters
        .map((filter) => filter.ref.current.getActiveFilters())
        .flat();
      props.onChange(
        activeFilters.map((filter) => ({ ...filter, values: [] }))
      );
    }
  }

  function pillRemoveHandler(param, value) {
    console.log(param, value);
    if (props.onChange) {
      const activeFilters = props.filters
        .map((filter) => filter.ref.current.getActiveFilters())
        .flat();
      props.onChange(
        activeFilters.map((filter) =>
          filter.param === param
            ? {
                ...filter,
                values: filter.values.filter((item) => item !== value),
              }
            : filter
        )
      );
    }
  }

  const pills = [];
  props.filters.forEach((filter) => {
    if (filter.type === "venue" && filter.value) {
      const selectedOption = filter.options.find(
        (option) => option.value === filter.value
      );
      const label = selectedOption ? selectedOption.label : filter.value;

      pills.push({
        filter: filter.label,
        param: filter.param,
        label,
        value: filter.value,
      });

      // pills.push(

      //   ...filter.options
      //     .filter((option) => option.selected)
      //     .map((option) => ({
      //       filter: filter.label,
      //       param: filter.param,
      //       label: option.label,
      //       value: option.value,
      //     }))
      // );
    }
    if (filter.type === "checkbox") {
      pills.push(
        ...filter.options
          .filter((option) => option.selected)
          .map((option) => ({
            filter: filter.label,
            param: filter.param,
            label: option.label,
            value: option.value,
          }))
      );
    }
    if (filter.type === "date") {
      if (filter.beforeValue) {
        pills.push({
          filter: `${filter.label} before`,
          param: filter.beforeParam,
          label: utcToLocal(...filter.beforeValue.split(" "))[0],
          value: filter.beforeValue,
        });
      }
      if (filter.afterValue) {
        pills.push({
          filter: `${filter.label} after`,
          param: filter.afterParam,
          label: utcToLocal(...filter.afterValue.split(" "))[0],
          value: filter.afterValue,
        });
      }
    }
  });

  return (
    <div className="vstack gap-2">
      <div className="row g-2 align-items-center px-sm-2">
        <div className="col-auto d-none d-sm-block">
          <span
            className="material-symbols-rounded user-select-none"
            style={{
              fontSize: "1.5em",
              verticalAlign: "-20%",
              fontVariationSettings: `'FILL' 1, 'wght' 500, 'GRAD' 0, 'opsz' 20`,
            }}
            aria-hidden="true"
          >
            filter_list
          </span>
        </div>
        {props.filters.map((filter, index) => (
          <div key={index} className="col-12 col-sm-auto">
            <div className="dropdown">
              <button
                className="btn btn-outline-dark dropdown-toggle w-100"
                type="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                {filter.label}
              </button>
              {filter.type === "checkbox" && (
                <FilterCheckbox
                  ref={filter.ref}
                  param={filter.param}
                  options={filter.options}
                />
              )}
              {filter.type === "date" && (
                <FilterDate
                  ref={filter.ref}
                  beforeParam={filter.beforeParam}
                  afterParam={filter.afterParam}
                  beforeValue={filter.beforeValue}
                  afterValue={filter.afterValue}
                />
              )}
              {filter.type === "venue" && (
                <FilterVenue
                  ref={filter.ref}
                  options={filter.options}
                  param={filter.param}
                  value={filter.value}
                />
              )}
            </div>
          </div>
        ))}
        <div className="col-12 col-sm-auto">
          <button
            className="btn btn-primary w-100"
            type="button"
            onClick={applyClickHandler}
          >
            Apply
          </button>
        </div>
      </div>
      <div className="row g-2">
        {pills.map((pill) => (
          <div
            key={`${pill.param}-${pill.value}`}
            className="col-12 col-sm-auto"
          >
            <FilterPill
              filter={pill.filter}
              param={pill.param}
              label={pill.label}
              value={pill.value}
              onRemove={pillRemoveHandler}
            />
          </div>
        ))}
        {pills.length > 0 && (
          <div className="col-12 col-sm-auto">
            <button
              className="btn btn-secondary w-100"
              type="button"
              onClick={clearClickHandler}
            >
              Clear
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default Filters;

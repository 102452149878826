import React from 'react';

function capitalize(s) {
  return s.charAt(0).toUpperCase().concat(s.substring(1));
}

const PaginationSummary = (props) => {
  const firstItem = (props.page - 1) * props.size + 1;
  const lastItem = firstItem + props.itemCount - 1;

  function sizeChangeHandler(event) {
    props.onPaginationChange(1, parseInt(event.target.value, 10));
  }

  return (
    <div className="container-fluid p-0">
      <div className="row align-items-center">
        <div className="col-auto">
          <div className="d-flex gap-2 align-items-center">
            <label
              className="form-label m-0 d-none d-md-inline"
              htmlFor="sizeSelect"
            >{`${
              props.itemLabelPlural
                ? capitalize(props.itemLabelPlural)
                : 'Items'
            } per page:`}</label>
            <select
              id="sizeSelect"
              className="form-select"
              value={props.size}
              onChange={sizeChangeHandler}
            >
              {props.allowedSizes.map((size) => (
                <option key={size} value={size}>
                  {size}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="col-auto d-none d-lg-inline">{`${
          props.itemCount ? `${firstItem}-${lastItem}` : '0'
        } of ${props.totalCount} ${
          props.itemLabelPlural ? props.itemLabelPlural : 'items'
        }`}</div>
      </div>
    </div>
  );
};

export default PaginationSummary;

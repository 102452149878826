import Main from '../pages/Venues/Main';
import Create from '../pages/Venues/Create';
import View from '../pages/Venues/View';
import Edit from '../pages/Venues/Edit';

const routes = [
  {
    path: '/venues',
    element: <Main />,
    loader: ({ params, request }) => {
      return null;
    },
  },
  {
    path: '/venues/create',
    element: <Create />,
    loader: ({ params, request }) => {
      return null;
    },
  },
  {
    path: '/venues/:code',
    element: <View />,
    loader: ({ params, request }) => {
      return null;
    },
  },
  {
    path: '/venues/:code/edit',
    element: <Edit />,
    loader: ({ params, request }) => {
      return null;
    },
  },
];

export default routes;

import Main from '../pages/Profile/Main';
import Edit from '../pages/Profile/Edit';
import ChangePassword from '../pages/Profile/ChangePassword';

const routes = [
  {
    path: '/profile',
    element: <Main />,
    loader: ({ params, request }) => {
      const code = sessionStorage.getItem('code');
      return Promise.all([
        fetch(`/api/members/${code}`).then((result) => result.json()),
      ]);
    },
  },
  {
    path: '/profile/edit',
    element: <Edit />,
    loader: ({ params, request }) => {
      const code = sessionStorage.getItem('code');
      return Promise.all([
        fetch(`/api/members/${code}`).then((result) => result.json()),
      ]);
    },
  },
  {
    path: '/profile/change-password',
    element: <ChangePassword />,
    loader: ({ params, request }) => {
      const code = sessionStorage.getItem('code');
      return Promise.all([
        fetch(`/api/members/${code}`).then((result) => result.json()),
      ]);
    },
  },
];

export default routes;

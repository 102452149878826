import React, { useEffect, useRef, useState } from "react";
import { useLoaderData } from "react-router-dom";
import { Mutex } from "async-mutex";
import MasterPage from "../../../components/MasterPage";
import QrScanner from "../../../components/QrScanner";
import Toast from "../../../components/Toast";
import UnregisteredModal from "./components/UnregisteredModal";

const modalShownMutex = new Mutex();
let modalShown = false;
const qrCodesMutex = new Mutex();
const sameValueDelay = 10000;
let qrcodes = [];

const CheckIn = (props) => {
  // const scannerRef = useRef(null);

  useEffect(() => {
    const interval = setInterval(async () => {
      const release = await qrCodesMutex.acquire();
      try {
        const now = Date.now();
        qrcodes = qrcodes.filter(
          (code) => now - code.timestamp < sameValueDelay
        );
      } finally {
        release();
      }
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  const [{ event: eventBase }] = useLoaderData();

  const [event, setEvent] = useState(eventBase);

  const breadcrumb = [
    { label: "Events", path: "/events?sort=start-date&direction=desc" },
    { label: event.code, path: `/events/${event.code}` },
    { label: "Check-In" },
  ];

  const modalRef = useRef();
  const toastRef = useRef();

  async function resultHandler(scanResult) {
    const modalShownRelease = await modalShownMutex.acquire();
    try {
      if (modalShown) {
        return;
      }
    } finally {
      modalShownRelease();
    }
    console.log("Scanning...");
    if (scanResult === undefined) {
      return;
    }
    const text = scanResult.data;
    console.log("Scanned:", text);
    const qrCodesRelease = await qrCodesMutex.acquire();
    try {
      if (qrcodes.some((code) => code.text === text)) {
        console.log("Same value scanned recently");
        return;
      }
      console.log("New value scanned");
      qrcodes.push({ text: text, timestamp: Date.now() });
      console.log("qrcodes:", qrcodes);
    } finally {
      qrCodesRelease();
    }
    const code = parseInt(text, 10);
    console.log("Code:", code);
    if (Number.isNaN(code) || code < 0) {
      console.log("Invalid QR Code!");
      toastRef.current.show("Invalid QR Code!");
      return;
    }
    let response = await fetch(`/api/events/${event.code}/attendees/${code}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        checkIn: true,
      }),
    });
    if (!response.ok) {
      console.log(event);
      // TODO: Handle error
      const responseData = await response.json();
      const { message } = responseData;
      if (message === "Attendee not found") {
        if (event.registrationRequired) {
          modalRef.current.open(async () => {
            let response = await fetch(`/api/events/${event.code}/attendees`, {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({
                code,
                checkIn: true,
              }),
            });
            console.log(response);
            if (!response.ok) {
              // TODO: Handle error
              return;
            }
            toastRef.current.show(
              "Attendee registered and checked successfully!",
              "success"
            );
            response = await fetch(`/api/events/${event.code}`);
            if (!response.ok) {
              // TODO: Handle error
              return;
            }
            const data = await response.json();
            setEvent(data.event);
          });
          const modalShownRelease = await modalShownMutex.acquire();
          try {
            modalShown = true;
          } finally {
            modalShownRelease();
          }
        } else {
          let response = await fetch(`/api/events/${event.code}/attendees`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              code,
              checkIn: true,
            }),
          });
          console.log(response);
          if (!response.ok) {
            // TODO: Handle error
            return;
          }
          toastRef.current.show(
            "Attendee registered and checked successfully!",
            "success"
          );
          response = await fetch(`/api/events/${event.code}`);
          if (!response.ok) {
            // TODO: Handle error
            return;
          }
          const data = await response.json();
          setEvent(data.event);
        }

        return;
      }
    }
    toastRef.current.show("Attendee checked successfully!", "success");
    response = await fetch(`/api/events/${event.code}`);
    if (!response.ok) {
      // TODO: Handle error
      return;
    }
    const data = await response.json();
    setEvent(data.event);
    // console.log('Attendee updated');
  }

  return (
    <MasterPage breadcrumb={breadcrumb}>
      <div className="hstack justify-content-center">
        <div
          className="vstack align-items-center gap-3 p-3"
          style={{ maxWidth: "800px" }}
        >
          <h1>{`${event.name} - Check-In`}</h1>
          <QrScanner onResult={resultHandler} />
          <div className="border border-info rounded bg-info-subtle w-100 p-3">
            <div className="row justify-content-center">
              <div className="col-12 col-md-auto">
                <span className="text-nowrap">
                  <strong>Registered:</strong> {event.registered}
                </span>
              </div>
              <div className="col-12 col-md-auto">
                <span className="text-nowrap">
                  <strong>Checked:</strong> {event.checked}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <UnregisteredModal
        ref={modalRef}
        onClose={async () => {
          const modalShownRelease = await modalShownMutex.acquire();
          try {
            modalShown = false;
          } finally {
            modalShownRelease();
          }
        }}
      />
      <Toast ref={toastRef} />
    </MasterPage>
  );
};

export default CheckIn;

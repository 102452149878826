import React, { useMemo } from 'react';
import FormItem from './components/FormItem';

const breakpoints = ['xs', 'sm', 'md', 'lg', 'xl', 'xxl'];

function breakpointToClass(breakpoint, size) {
  return breakpoint === 'xs' ? `col-${size}` : `col-${breakpoint}-${size}`;
}

function makeClasses(labelContainerSize, layoutBreakpoint) {
  const breakpointIndex = breakpoints.indexOf(layoutBreakpoint);
  let labelContainerClass = 'col-auto';
  let inputContainerClass = `col-12`;
  if (breakpointIndex !== -1) {
    let breakpoint;
    for (let i = breakpointIndex; i < breakpoints.length; i += 1) {
      breakpoint = breakpoints[i];
      const size = labelContainerSize[breakpoint];
      if (size) {
        labelContainerClass += ' ' + breakpointToClass(breakpoint, size);
        inputContainerClass += ' ' + breakpointToClass(breakpoint, 12 - size);
      }
    }
  }
  return [labelContainerClass, inputContainerClass];
}

const FormItems = (props) => {
  const [labelContainerClass, inputContainerClass] = useMemo(() => {
    return makeClasses(props.labelContainerSize, props.breakpoint);
  }, [props.labelContainerSize, props.breakpoint]);
  return (
    <div className="vstack gap-3">
      {props.items.map((item, index) => (
        <FormItem
          key={index}
          labelContainerClass={labelContainerClass}
          inputContainerClass={inputContainerClass}
          formId={props.formId}
          formValidationDisabled={props.validationDisabled}
          breakpoint={props.breakpoint}
          {...item}
        ></FormItem>
      ))}
    </div>
  );
};

export default FormItems;

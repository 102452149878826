import React, { useId } from 'react';
import { Link } from 'react-router-dom';
import navItems from './navItems';
import NavItem from './components/NavItem';
import UserDropdown from './components/UserDropdown';

const Header = () => {
  const headerId = useId();
  const offcanvasId = `${headerId}-offcanvas`;
  const offcanvasTitleId = `${headerId}-offcanvas-title`;

  return (
    <header
      id={headerId}
      className="bg-dark sticky-top user-select-none"
      data-bs-theme="dark"
    >
      <div className="container-xxl px-0 py-2">
        <nav
          className="navbar navbar-expand-lg px-3 py-0"
          style={{ height: '40px' }}
        >
          <button
            className="navbar-toggler h-100 me-3 px-1"
            type="button"
            data-bs-toggle="offcanvas"
            data-bs-target={`#${offcanvasId}`}
            aria-controls={offcanvasId}
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <Link className="navbar-brand me-auto me-lg-4 p-0" to="/">
            <div className="hstack">
              <div className="bg-white rounded-3 me-2 p-1 d-none d-lg-block">
                <img
                  className="d-block"
                  style={{ height: '32px', width: '32px' }}
                  src="/logo.svg"
                  alt="bld logo"
                />
              </div>
              BLD Management
            </div>
          </Link>
          <div
            id={offcanvasId}
            className="offcanvas offcanvas-start nav-pills"
            tabIndex="-1"
            aria-labelledby={offcanvasTitleId}
          >
            <div className="offcanvas-header border-bottom">
              <span
                id={offcanvasTitleId}
                className="offcanvas-title fs-5"
                style={{ cursor: 'default' }}
              >
                Main menu
              </span>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="offcanvas"
                aria-label="Close"
              ></button>
            </div>
            <div className="offcanvas-body">
              <ul className="navbar-nav">
                {navItems.map((item, index) => (
                  <NavItem
                    key={index}
                    path={item.path}
                    name={item.name}
                    icon={item.icon}
                  />
                ))}
              </ul>
            </div>
          </div>
          <UserDropdown />
        </nav>
      </div>
    </header>
  );
};

export default Header;

import React from 'react';
import { Link } from 'react-router-dom';

const BreadcrumbItem = (props) => {
  return (
    <li className="breadcrumb-item">
      {!props.first && (
        <span
          className="material-symbols-rounded me-1 text-body-secondary user-select-none"
          style={{
            fontVariationSettings: `'FILL' 1, 'wght' 600, 'GRAD' 0, 'opsz' 20`,
            fontSize: '1em',
            verticalAlign: '-15%',
          }}
          aria-hidden="true"
        >
          arrow_forward_ios
        </span>
      )}
      {props.current ? (
        <span
          className="fw-bold text-nowrap text-body-secondary"
          style={{ cursor: 'default' }}
        >
          {props.label}
        </span>
      ) : (
        <Link className="text-nowrap" to={props.path}>
          {props.label}
        </Link>
      )}
    </li>
  );
};

export default BreadcrumbItem;

import React, {
  forwardRef,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import Input from '../Input';
import styles from './index.module.css';

const InputPassword = forwardRef((props, ref) => {
  const { revealButton, type, ...innerProps } = props;

  const [hiddenPassword, setHiddenPassword] = useState(true);

  const inputRef = useRef(null);

  useImperativeHandle(ref, () => ({
    focus: () => {
      inputRef.current.focus();
    },
  }));

  function revealButtonClickHandler() {
    setHiddenPassword((previous) => !previous);
  }

  return (
    <Input
      ref={inputRef}
      type={hiddenPassword ? 'password' : 'text'}
      {...innerProps}
    >
      {revealButton && (
        <button
          className={`p-0 ${styles.reveal}`}
          type="button"
          onClick={revealButtonClickHandler}
          aria-label="Reveal password"
        >
          <span
            className={`material-symbols-rounded d-block user-select-none ${styles.icon}`}
            aria-hidden="true"
          >
            {hiddenPassword ? 'visibility' : 'visibility_off'}
          </span>
        </button>
      )}
    </Input>
  );
});

export default InputPassword;

import React, {
  forwardRef,
  Fragment,
  useId,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';

const UnregisteredModal = forwardRef((props, ref) => {
  const modalId = useId();
  const titleId = `${modalId}-title`;

  const [onAccept, setOnAccept] = useState(null);

  const openButtonRef = useRef();
  const cancelButtonRef = useRef();

  useImperativeHandle(ref, () => ({
    open: (callback) => {
      setOnAccept(() => callback);
      openButtonRef.current.click();
    },
  }));

  function closeHandler() {
    if (props.onClose) {
      props.onClose();
    }
  }

  function acceptHandler() {
    if (onAccept) {
      onAccept();
    }
    cancelButtonRef.current.click();
  }

  return (
    <Fragment>
      <div
        className="modal fade"
        id={modalId}
        tabIndex="-1"
        aria-labelledby={titleId}
        aria-hidden="true"
        onClick={closeHandler}
      >
        <div
          className="modal-dialog modal-dialog-centered"
          onClick={(event) => {
            event.stopPropagation();
          }}
        >
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id={titleId}>
                <span
                  className="material-symbols-rounded user-select-none text-warning me-1"
                  style={{
                    fontSize: '1.5rem',
                    verticalAlign: '-15%',
                    fontVariationSettings:
                      "'FILL' 1, 'wght' 900, 'GRAD' 0, 'opsz' 48",
                  }}
                  aria-hidden="false"
                >
                  warning
                </span>
                Warning!
              </h1>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={closeHandler}
              ></button>
            </div>
            <div className="modal-body">
              Member not registered. Would you like to check in the user for
              this event?
            </div>
            <div className="modal-footer">
              <div className="row g-2 flex-md-row-reverse w-100 mx-0">
                <div className="col-12 col-md-auto">
                  <button
                    type="button"
                    className="btn btn-primary w-100"
                    onClick={acceptHandler}
                  >
                    Yes
                  </button>
                </div>
                <div className="col-12 col-md-auto">
                  <button
                    ref={cancelButtonRef}
                    type="button"
                    className="btn btn-secondary w-100"
                    data-bs-dismiss="modal"
                    onClick={closeHandler}
                  >
                    No
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <button
        ref={openButtonRef}
        className="visually-hidden"
        type="button"
        tabIndex="-1"
        aria-hidden="true"
        data-bs-toggle="modal"
        data-bs-target={`#${modalId}`}
      >
        Open
      </button>
    </Fragment>
  );
});

export default UnregisteredModal;

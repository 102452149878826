export function capitalize(s) {
  return s.charAt(0).toUpperCase().concat(s.substring(1));
}

export function prepareParams(currentParams, newParams) {
  const params = {};
  Object.entries(currentParams).forEach(([key, value]) => {
    if (value !== null) {
      params[key] = value;
    }
  });
  if (newParams) {
    Object.entries(newParams).forEach(([key, value]) => {
      if (value === null) {
        delete params[key];
        return;
      }
      params[key] = value;
    });
  }
  return params;
}

import React, { Fragment } from 'react';
import Header from './components/Header';
import Breadcrumb from './components/Breadcrumb';
import Footer from './components/Footer';

const containerClass =
  'container-xxl flex-grow-1 border-start border-end border-dark-subtle ' +
  'bg-body-tertiary px-0';

const MasterPage = (props) => {
  return (
    <Fragment>
      <Header />
      <main className="vstack flex-grow-1">
        <div className={containerClass}>
          <div className="vstack">
            {props.breadcrumb.length > 0 && (
              <Breadcrumb items={props.breadcrumb} />
            )}
            <div className="vstack align-items-center gap-3 p-3">
              {props.title && (
                <div className="text-center">
                  <h1 className="m-0">{props.title}</h1>
                </div>
              )}
              {props.children}
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </Fragment>
  );
};

export default MasterPage;

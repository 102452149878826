import React, { useRef } from 'react';
import { Link, useLoaderData, useNavigate } from 'react-router-dom';
import MasterPage from '../../../components/MasterPage';
import Form from '../../../components/Form';

const breadcrumb = [{ label: 'Profile', url: '/profile' }];

const Main = () => {
  const navigate = useNavigate();

  const [{ member }] = useLoaderData();

  const codeRef = useRef(null);
  const firstNameRef = useRef(null);
  const lastNameRef = useRef(null);
  const emailRef = useRef(null);
  const phonesRef = useRef(null);

  return (
    <MasterPage breadcrumb={breadcrumb} title="Profile">
      <div
        className="row justify-content-end"
        style={{ width: 'calc(min(100%, 800px))' }}
      >
        <div className="col-12 col-sm-auto px-0">
          <Link className="btn btn-primary w-100" to="/profile/change-password">
            Change Password
          </Link>
        </div>
      </div>
      <div
        className="border rounded bg-white p-3"
        style={{ width: 'calc(min(100%, 800px))' }}
      >
        <Form
          id="profile-form"
          items={[
            {
              id: 'code',
              ref: codeRef,
              name: 'code',
              label: 'Code',
              type: 'text',
              value: member.code,
              size: { xs: 12, sm: '6', md: '4' },
              disabled: true,
            },
            {
              id: 'firstName',
              ref: firstNameRef,
              name: 'firstName',
              label: 'First Name',
              type: 'text',
              value: member.firstName,
              size: { xs: 12 },
              disabled: true,
            },
            {
              id: 'lastName',
              ref: lastNameRef,
              name: 'lastName',
              label: 'Last Name',
              type: 'text',
              value: member.lastName,
              size: { xs: 12 },
              disabled: true,
            },
            {
              id: 'email',
              ref: emailRef,
              name: 'email',
              label: 'Email',
              type: 'email',
              value: member.email,
              size: { xs: 12 },
              disabled: true,
            },
            {
              id: 'phones',
              ref: phonesRef,
              name: 'phones',
              label: 'Phones',
              type: 'text',
              value: member.phones,
              size: { xs: 12, sm: '8', md: '6' },
              disabled: true,
            },
          ]}
          actions={[
            {
              label: 'Edit',
              type: 'button',
              onClick: () => {
                navigate('/profile/edit');
              },
              variant: 'primary',
            },
          ]}
          layoutBreakpoint="sm"
          actionsBreakpoint="sm"
          labelContainerSize={{ sm: 2 }}
        />
      </div>
    </MasterPage>
  );
};

export default Main;

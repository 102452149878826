import React from 'react';
import { useNavigate } from 'react-router-dom';

const ItemCard = (props) => {
  const navigate = useNavigate();
  return (
    <div className="card h-100 border shadow-sm">
      <div className="card-header">
        <span className="card-title text-center fw-bold">
          {props.data.title}
        </span>
      </div>
      <div className="card-body">
        <div className="row row-cols-2">
          <div className="col d-flex justify-content-center align-items-center">
            <span
              className="material-symbols-rounded user-select-none me-2"
              style={{
                fontSize: '100px',
                verticalAlign: '-15%',
                fontVariationSettings: `'FILL' 1, 'wght' 400, 'GRAD' 0, 'opsz' 20`,
              }}
              aria-hidden="true"
            >
              {props.data.icon}
            </span>
            {/* <props.data.icon width="80px" fill={'#444'} /> */}
          </div>
          <div className="col">
            <div className="row row-cols-1 h-100 g-3">
              {props.data.links.map((link) => (
                <div
                  key={link.label}
                  className="col d-flex flex-column justify-content-center"
                >
                  <button
                    className="btn btn-primary"
                    type="button"
                    onClick={() => {
                      navigate(link.url);
                    }}
                  >
                    {link.label}
                  </button>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ItemCard;

import React, { useEffect, useRef } from 'react';
import QrScanner from 'qr-scanner';
import './index.css';

const scanRegionMultiplier = 9 / 10;
const downScaleSize = 400;

function calculateScanRegion(video) {
  const smallestDimension = Math.min(video.videoWidth, video.videoHeight);
  const scanRegionSize = Math.round(scanRegionMultiplier * smallestDimension);
  return {
    x: Math.round((video.videoWidth - scanRegionSize) / 2),
    y: Math.round((video.videoHeight - scanRegionSize) / 2),
    width: scanRegionSize,
    height: scanRegionSize,
    downScaledWidth: downScaleSize,
    downScaledHeight: downScaleSize,
  };
}

const Scanner = (props) => {
  const videoRef = useRef(null);

  useEffect(() => {
    const video = videoRef.current;
    const qrScanner = new QrScanner(
      video,
      (result) => {
        if (props.onResult) {
          props.onResult(result);
        }
      },
      {
        maxScansPerSecond: 5,
        calculateScanRegion,
        returnDetailedScanResult: true,
        highlightScanRegion: true,
        highlightCodeOutline: true,
      }
    );
    qrScanner.start();
    return () => {
      qrScanner.destroy();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    // <div className="hstack justify-content-center rounded bg-dark">
    //   <div
    //     className="position-relative overflow-hidden"
    //     style={{  aspectRatio: '1/1' }}
    //   >
    //     <div
    //       className="position-absolute d-block h-100 traslate-left-50"
    //       style={{
    //         left: '50%',
    //       }}
    //     >
    //       <video ref={videoRef} className="h-100"></video>
    //     </div>width: 'calc(min(600px, 100%))',
    //   </div>
    // </div>
    <div className="hstack justify-content-center rounded bg-dark overflow-hidden">
      <video
        ref={videoRef}
        style={{
          width: 'calc(min(600px, 100%))',
          aspectRatio: '1/1',
          objectFit: 'cover',
        }}
      ></video>
    </div>
  );
};

export default Scanner;

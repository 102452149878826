import React, { useRef } from 'react';
import { useLoaderData, useNavigate } from 'react-router-dom';
import MasterPage from '../../../components/MasterPage';
import Form from '../../../components/Form';
import Toast from '../../../components/Toast';

const View = (props) => {
  const navigate = useNavigate();

  const { member } = useLoaderData();

  const breadcrumb = [
    { label: 'Members', path: '/members' },
    { label: member.code },
  ];

  const toastRef = useRef(null);

  const formProps = {
    id: 'member-form',
    className: 'border rounded px-3 py-3 py-md-5',
    labelContainerSize: { md: 3 },
    items: [
      {
        id: 'code',
        name: 'code',
        label: 'Code',
        type: 'text',
        value: `${member.code}`,
        size: { xs: 12, md: '8', lg: '6', xl: '5' },
        disabled: true,
      },
      {
        id: 'role',
        name: 'role',
        label: 'Role',
        type: 'text',
        value: member.role,
        size: { xs: 12, md: '8', lg: '6', xl: '5' },
        disabled: true,
      },
      {
        id: 'first-name',
        name: 'firstName',
        label: 'First name',
        type: 'text',
        value: member.firstName,
        size: { xs: 12 },
        disabled: true,
      },
      {
        id: 'last-name',
        name: 'lastName',
        label: 'Last name',
        type: 'text',
        value: member.lastName,
        size: { xs: 12 },
        disabled: true,
      },
      {
        id: 'email',
        name: 'email',
        label: 'Email',
        type: 'text',
        value: member.email,
        size: { xs: 12 },
        disabled: true,
      },
      {
        id: 'phones',
        name: 'phones',
        label: 'Phones',
        type: 'text',
        value: member.phones,
        size: { xs: 12 },
        disabled: true,
      },
      {
        id: 'gender',
        name: 'gender',
        label: 'Gender',
        type: 'text',
        value: member.gender,
        size: { xs: 12, md: '8', lg: '6', xl: '5' },
        disabled: true,
      },
      {
        id: 'birth-date',
        name: 'birthDate',
        label: 'Birth date',
        type: 'text',
        value: member.birthDate,
        size: { xs: 12, md: '8', lg: '6', xl: '5' },
        disabled: true,
      },
      {
        id: 'civil-status',
        name: 'civilStatus',
        label: 'Civil Status',
        type: 'text',
        value: member.civilStatus,
        size: { xs: 12, md: '8', lg: '6', xl: '5' },
        disabled: true,
      },
      {
        id: 'education',
        name: 'education',
        label: 'Education',
        type: 'area',
        value: member.education,
        size: { xs: 12 },
        disabled: true,
      },
      {
        id: 'occupation',
        name: 'occupation',
        label: 'Occupation',
        type: 'area',
        value: member.occupation,
        size: { xs: 12 },
        disabled: true,
      },
      {
        id: 'work-schedule',
        name: 'workSchedule',
        label: 'Work Schedule',
        type: 'area',
        value: member.workSchedule,
        size: { xs: 12 },
        disabled: true,
      },
      {
        id: 'hobbies',
        label: 'Hobbies',
        name: 'hobbies',
        type: 'area',
        value: member.hobbies,
        size: { xs: 12 },
        disabled: true,
      },
      {
        id: 'medical-conditions',
        name: 'medicalConditions',
        label: 'Medical Conditions',
        type: 'area',
        value: member.medicalConditions,
        size: { xs: 12 },
        disabled: true,
      },
      {
        id: 'diet-restrictions',
        name: 'dietRestrictions',
        label: 'Diet Restrictions',
        type: 'area',
        value: member.dietRestrictions,
        size: { xs: 12 },
        disabled: true,
      },
      {
        id: 'food-allergies',
        name: 'foodAllergies',
        label: 'Food Allergies',
        type: 'area',
        value: member.foodAllergies,
        size: { xs: 12 },
        disabled: true,
      },
      {
        id: 'is-active-laic',
        name: 'isActiveLaic',
        label: 'Active laic',
        type: 'switch',
        value: member.isActiveLaic,
        size: { xs: 12 },
        disabled: true,
      },
      {
        id: 'is-enabled',
        name: 'isEnabled',
        label: 'Enabled',
        type: 'switch',
        value: member.isEnabled,
        size: { xs: 12 },
        disabled: true,
      },
    ],
    actions: [
      {
        label: 'Edit',
        type: 'button',
        onClick: () => {
          navigate(`/members/${member.code}/edit`);
        },
        variant: 'primary',
      },
    ],
    maxWidth: '800px',
    layoutBreakpoint: 'md',
    actionsBreakpoint: 'sm',
  };

  return (
    <MasterPage breadcrumb={breadcrumb}>
      <div className="vstack gap-3 m-3" style={{}}>
        <div className="hstack justify-content-md-center align-items-center position-relative">
          <h1>Member Details</h1>
        </div>
        <div className="hstack justify-content-center">
          <Form {...formProps} />
        </div>
      </div>
      <Toast ref={toastRef} />
    </MasterPage>
  );
};

export default View;

import React, { useRef } from 'react';
import { useLoaderData, useNavigate } from 'react-router-dom';
import MasterPage from '../../../components/MasterPage';
import Form from '../../../components/Form';
import Toast from '../../../components/Toast';

const lowerRegex = /[a-z]/;
const upperRegex = /[A-Z]/;
const digitRegex = /[0-9]/;
const specialRegex = /[^a-zA-Z0-9]/;

const breadcrumb = [
  { label: 'Profile', path: '/profile' },
  { label: 'Change Password' },
];

const ChangePassword = () => {
  const navigate = useNavigate();

  const [{ member }] = useLoaderData();

  const formRef = useRef(null);
  const currentPasswordRef = useRef(null);
  const newPasswordRef = useRef(null);
  const confirmPasswordRef = useRef(null);

  const toastRef = useRef(null);

  const items = [
    {
      id: 'current-password',
      ref: currentPasswordRef,
      name: 'currentPassword',
      label: 'Current Password',
      type: 'password',
      revealButton: true,
      value: '',
      size: { xs: 12 },
      autoComplete: 'current-password',
      required: true,
      validations: [
        {
          validate: (value) => value.length > 0,
          message: 'Current password is required.',
        },
      ],
    },
    {
      id: 'new-password',
      ref: newPasswordRef,
      name: 'newPassword',
      label: 'New Password',
      type: 'password',
      revealButton: true,
      value: '',
      size: { xs: 12 },
      autoComplete: 'new-password',
      required: true,
      validations: [
        {
          validate: (value) => value.length > 0,
          message: 'New password is required.',
        },
        {
          validate: (value) => value.length === 0 || value.length >= 8,
          message: 'New password must have at least 8 characters.',
        },
        {
          validate: (value) => value.length === 0 || lowerRegex.test(value),
          message: 'New password must have at least one lowercase letter.',
        },
        {
          validate: (value) => value.length === 0 || upperRegex.test(value),
          message: 'New password must have at least one uppercase letter.',
        },
        {
          validate: (value) => value.length === 0 || digitRegex.test(value),
          message: 'New password must have at least one digit.',
        },
        {
          validate: (value) => value.length === 0 || specialRegex.test(value),
          message: 'New password must have at least one special character.',
        },
      ],
    },
    {
      id: 'confirm-password',
      ref: confirmPasswordRef,
      name: 'confirmPassword',
      label: 'Confirm Password',
      type: 'password',
      revealButton: true,
      value: '',
      size: { xs: 12 },
      autoComplete: 'new-password',
      required: true,
      validations: [
        {
          validate: (value) => value.length > 0,
          message: 'Confirm password is required.',
        },
        {
          validate: (value) =>
            value.length === 0 || value === newPasswordRef.current.getValue(),
          message: 'Must match the new password.',
        },
      ],
    },
  ];

  const actions = [
    {
      label: 'Save',
      type: 'submit',
      variant: 'primary',
    },
    {
      label: 'Cancel',
      type: 'button',
      variant: 'secondary',
      onClick: () => {
        navigate('/profile');
      },
    },
  ];

  async function submitHandler(event) {
    event.preventDefault();
    const isValid = await formRef.current.validate();
    if (!isValid) {
      toastRef.current.show(
        'Member update failed! Please check for errors.',
        'danger'
      );
      return;
    }
    const data = formRef.current.getData();
    console.log(data);
    const result = await fetch(`/api/change-password`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ code: member.code, ...data }),
    });
    if (result.ok) {
      toastRef.current.show('Password changed successfully!', 'success', () => {
        formRef.current.reset();
      });
      return;
    }
    toastRef.current.show(
      'Password change failed! Please try again.',
      'danger'
    );
  }

  return (
    <MasterPage breadcrumb={breadcrumb} title="Change Password">
      <div
        className="border rounded bg-white p-3"
        style={{ width: 'calc(min(100%, 800px))' }}
      >
        <Form
          ref={formRef}
          id="profile-form"
          items={items}
          actions={actions}
          layoutBreakpoint="sm"
          actionsBreakpoint="sm"
          labelContainerSize={{ sm: 3 }}
          onSubmit={submitHandler}
        >
          <input
            id="username"
            name="username"
            className="visually-hidden"
            type="text"
            value={member.email}
            autoComplete="username"
            readOnly={true}
            tabIndex={-1}
            aria-hidden="true"
          />
        </Form>
      </div>
      <Toast ref={toastRef} />
    </MasterPage>
  );
};

export default ChangePassword;

import React from 'react';
import MasterPage from '../../../components/MasterPage';

const code = 85;

const View = (props) => {
  const breadcrumb = [{ label: 'Reports', path: '/reports' }, { label: code }];
  return (
    <MasterPage breadcrumb={breadcrumb}>
      <div className="border rounded m-3 p-3" style={{}}>
        Reports View
      </div>
    </MasterPage>
  );
};

export default View;

import React from 'react';
import MasterPage from '../../../components/MasterPage';

const breadcrumb = [{ label: 'Venues', path: '/venues' }, { label: 'Create' }];

const Create = (props) => {
  return (
    <MasterPage breadcrumb={breadcrumb}>
      <div className="border rounded m-3 p-3" style={{}}>
        Venues Create
      </div>
    </MasterPage>
  );
};

export default Create;

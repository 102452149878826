import React from 'react';
import MasterPage from '../../../components/MasterPage';

const breadcrumb = [
  { label: 'Reports', path: '/reports' },
  { label: 'Create' },
];

const Create = (props) => {
  return (
    <MasterPage breadcrumb={breadcrumb}>
      <div className="border rounded m-3 p-3" style={{}}>
        Reports Create
      </div>
    </MasterPage>
  );
};

export default Create;

import React, {
  forwardRef,
  useEffect,
  useId,
  useImperativeHandle,
  useState,
} from "react";

import { localToUtc, utcToLocal } from "../../../../../../utils/utc";

const FilterDate = forwardRef((props, ref) => {
  const id = useId();
  const beforeId = `${id}-before`;
  const afterId = `${id}-after`;

  const [before, setBefore] = useState(
    props.beforeValue ? utcToLocal(...props.beforeValue.split(" "))[0] : ""
  );
  const [after, setAfter] = useState(
    props.afterValue ? utcToLocal(...props.afterValue.split(" "))[0] : ""
  );

  useEffect(() => {
    setBefore(
      props.beforeValue ? utcToLocal(...props.beforeValue.split(" "))[0] : ""
    );
    setAfter(
      props.afterValue ? utcToLocal(...props.afterValue.split(" "))[0] : ""
    );
  }, [props.beforeValue, props.afterValue]);

  useImperativeHandle(ref, () => ({
    getActiveFilters: () => {
      if (before) {
        console.log(before);
        console.log(localToUtc(before, "23:59:59.999").join(" "));
      }
      if (after) {
        console.log(after);
        console.log(localToUtc(after, "00:00:00.000").join(" "));
      }
      return [
        {
          param: props.beforeParam,
          values: before ? [localToUtc(before, "23:59:59.999").join(" ")] : [],
        },
        {
          param: props.afterParam,
          values: after ? [localToUtc(after, "00:00:00.000").join(" ")] : [],
        },
      ];
    },
  }));

  function beforeChangeHandler(event) {
    setBefore(event.target.value);
  }

  function afterChangeHandler(event) {
    setAfter(event.target.value);
  }

  return (
    <div
      className="dropdown-menu px-3 w-100 w-sm-auto"
      style={{ minWidth: "200px" }}
    >
      <div className="my-2">
        <label htmlFor={beforeId}>Before or equal to:</label>
        <input
          id={beforeId}
          type="date"
          className="form-control"
          value={before}
          onChange={beforeChangeHandler}
        />
      </div>
      <div className="my-2">
        <label htmlFor={afterId}>After or equal to:</label>
        <input
          id={afterId}
          type="date"
          className="form-control"
          value={after}
          onChange={afterChangeHandler}
        />
      </div>
    </div>
  );
});

export default FilterDate;

import React from 'react';
import { Link } from 'react-router-dom';

const PaginationItem = (props) => {
  return (
    <li
      className={`page-item d-flex align-items-center${
        props.disabled ? ' disabled' : ''
      }${props.active ? ' active' : ''}`}
    >
      {props.active || props.disabled ? (
        <span className={'page-link px-2 border-0 rounded cursor-default'}>
          {props.children}
        </span>
      ) : (
        <Link
          className="page-link px-2 border-0 rounded"
          onClick={props.onClick}
        >
          {props.children}
        </Link>
      )}
    </li>
  );
};

export default PaginationItem;

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import './index.css';
import styles from './index.module.css';

const icons = {
  success: 'check_circle',
  warning: 'warning',
  danger: 'error',
  info: 'info',
};

const ToastMessage = (props) => {
  const [state, setState] = useState('created');
  const [closingCount, setClosingCount] = useState(0);
  const [isCounting, setIsCounting] = useState(false);

  const icon = useMemo(() => icons[props.variant], [props.variant]);

  useEffect(() => {
    setState('displaying');
    const timer = setTimeout(() => {
      setIsCounting(true);
      setState('displayed');
    }, 1000);
    return () => {
      clearTimeout(timer);
    };
  }, []);

  useEffect(() => {
    let timer = null;
    if (isCounting) {
      if (closingCount === 20) {
        setIsCounting(false);
        setState('closing');
        timer = setTimeout(() => {
          props.onClose();
        }, 1000);
      }
      timer = setTimeout(() => {
        setClosingCount((previous) => previous + 1);
      }, 250);
    }
    return () => {
      clearTimeout(timer);
    };
  }, [closingCount, isCounting, props]);

  const mouseEnterHandler = useCallback(() => {
    setIsCounting(false);
  }, []);

  const mouseLeaveHandler = useCallback(() => {
    if (state === 'displayed') {
      setIsCounting(true);
    }
  }, [state]);

  const closeButtonClickHandler = useCallback(() => {
    setState('closing');
    setIsCounting(false);
    setTimeout(() => {
      props.onClose();
    }, 1000);
  }, [props]);

  return (
    <div
      className={
        `position-relative border border-2 border-${props.variant} rounded ` +
        `bg-white overflow-hidden pe-auto ${styles[state]}`
      }
      style={{ width: '500px', maxWidth: '100%' }}
      onMouseEnter={mouseEnterHandler}
      onMouseLeave={mouseLeaveHandler}
    >
      <div
        className={`bg-${props.variant} bg-opacity-25 text-${props.variant}-emphasis`}
      >
        <div className="hstack align-items-center p-3 pb-2">
          <span
            className="material-symbols-rounded me-3 user-select-none"
            style={{
              fontSize: '1.5em',
              fontVariationSettings:
                "'FILL' 0, 'wght' 400, 'GRAD' 0, 'opsz' 48",
            }}
            aria-hidden="true"
          >
            {icon}
          </span>
          <span className="me-auto">{props.message}</span>
          <button
            className="btn-close ms-3"
            style={{ shadow: 'none' }}
            type="button"
            onClick={closeButtonClickHandler}
            aria-label="Close"
          ></button>
        </div>
        <div
          className={`bg-${props.variant}`}
          style={{
            height: '10px',
            width: `${100 - 5 * closingCount}%`,
            transition: 'width 250ms linear',
          }}
        ></div>
      </div>
    </div>
  );
};

export default ToastMessage;

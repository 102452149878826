import React, { useEffect, useRef, useState } from "react";
import {
  Link,
  useLoaderData,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { Tooltip } from "react-tooltip";
import MasterPage from "../../../components/MasterPage";
import List from "../../../components/List";
import Toast from "../../../components/Toast";
import { prepareParams } from "../../../util";
import { utcToLocal } from "../../../utils/utc";

const breadcrumb = [{ label: "Events" }];

const defaultPageSize = 10;
const defaultSortField = "start-date";
const allowedSizes = [5, 10, 20];

const fields = [
  {
    name: "code",
    label: "Code",
    getValue: (item) => <Link to={`/events/${item.code}`}>{item.code}</Link>,
    cardRole: "subtitle",
    getCardValue: (item) => item.code,
  },
  {
    name: "name",
    label: "Name",
    getValue: (item) => item.name,
    cardRole: "title",
  },
  {
    name: "venue",
    label: "Venue",
    getValue: (item) => (
      <span
        data-tooltip-id="venue-tooltip"
        data-tooltip-content={`${item.venue}, ${item.city}, ${item.provinceCode}, ${item.country}`}
      >
        {item.venue}
      </span>
    ),
  },
  {
    name: "start-date",
    label: "Start Date",
    getValue: (item) => item.startDate.split("T")[0],
  },
  {
    name: "end-date",
    label: "End Date",
    getValue: (item) => item.endDate.split("T")[0],
  },
  {
    name: "attendance",
    label: "Attendance",
    getValue: (item) => `${item.checked} / ${item.registered}`,
    align: "center",
  },
  {
    name: "status",
    label: "Status",
    getValue: (item) => (
      <span className="text-nowrap">
        <span
          className={`badge p-1 me-2 ${
            item.status === "Upcoming"
              ? "bg-info"
              : item.status === "In Progress"
              ? "bg-success"
              : item.status === "Finalized"
              ? "bg-secondary"
              : "bg-danger"
          } border rounded-circle`}
        >
          <span className="visually-hidden"></span>
        </span>
        {item.status}
      </span>
    ),
  },
];

const Main = (props) => {
  const navigate = useNavigate();

  const [{ totalCount, events }, { venues }] = useLoaderData();

  const [items, setItems] = useState(
    events.map((event) => ({
      id: event.code,
      title: event.name,
      data: event,
      selected: false,
    }))
  );

  const toastRef = useRef(null);

  const venueFilterRef = useRef(null);
  const startDateFilterRef = useRef(null);
  const endDateFilterRef = useRef(null);
  const statusFilterRef = useRef(null);

  const [searchParams, setSearchParams] = useSearchParams();
  const pageParam = searchParams.get("page");
  const sizeParam = searchParams.get("size");
  const sortParam = searchParams.get("sort");
  const directionParam = searchParams.get("direction");
  const searchParam = searchParams.get("search");
  const venueParam = searchParams.get("venue");

  const startBeforeParam = searchParams.get("startbefore");

  const startAfterParam = searchParams.get("startafter");

  const endBeforeParam = searchParams.get("endbefore");

  const endAfterParam = searchParams.get("endafter");

  const statusParam = searchParams.getAll("status");

  const currentParams = {
    page: pageParam ? parseInt(pageParam, 10) : 1,
    size: sizeParam ? parseInt(sizeParam, 10) : defaultPageSize,
    sort: sortParam || defaultSortField,
    direction: directionParam || "asc",
    search: searchParam || null,
    venue: venueParam || null,
    startbefore: startBeforeParam || null,
    startafter: startAfterParam || null,
    endbefore: endBeforeParam || null,
    endafter: endAfterParam || null,
    status: statusParam,
  };

  const filters = [
    {
      label: "Venue",
      type: "venue",
      param: "venue",
      ref: venueFilterRef,
      value: currentParams.venue || "",
      options: venues.map((venue) => ({
        value: `${venue.id}`,
        label: `${venue.name}, ${venue.city}, ${venue.provinceCode}, ${venue.country}`,
      })),
    },
    {
      label: "Start Date",
      type: "date",
      ref: startDateFilterRef,
      beforeParam: "startbefore",
      afterParam: "startafter",
      beforeValue: currentParams.startbefore || "",
      afterValue: currentParams.startafter || "",
    },
    {
      label: "End Date",
      type: "date",
      ref: endDateFilterRef,
      beforeParam: "endbefore",
      afterParam: "endafter",
      beforeValue: currentParams.endbefore || "",
      afterValue: currentParams.endafter || "",
    },
    {
      label: "Status",
      type: "checkbox",
      param: "status",
      ref: statusFilterRef,
      options: ["Upcoming", "In Progress", "Finalized", "Cancelled"].map(
        (status) => ({
          label: status,
          value: status,
          selected: currentParams.status.includes(status),
        })
      ),
    },
  ];

  const bulkActions = [];

  const actions = [
    {
      label: "View",
      icon: "visibility",
      onClick: (id) => {
        navigate(`/events/${id}`);
      },
    },
    {
      label: "Edit",
      icon: "edit",
      onClick: (id) => {
        navigate(`/events/${id}/edit`);
      },
    },
    {
      label: "Check-in",
      icon: "qr_code_scanner",
      onClick: (id) => {
        navigate(`/events/${id}/check-in`);
      },
      condition: (item) => item.status !== "Cancelled",
    },
    {
      label: "Cancel",
      icon: "delete",
      onClick: async (id) => {
        const result = await fetch(`/api/events/${id}`, {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ isCancelled: true }),
        });
        if (!result.ok) {
          //TODO: handle error
        }
        setItems((previous) =>
          previous.map((item) =>
            item.id === id
              ? { ...item, data: { ...item.data, status: "Cancelled" } }
              : item
          )
        );
        toastRef.current.show(
          "Event cancelled successfully!",
          "success",
          () => {
            setSearchParams(prepareParams(currentParams, {}));
          }
        );
      },
      condition: (item) =>
        item.status !== "Cancelled" && item.status !== "Finalized",
    },
    {
      label: "Restore",
      icon: "restore_from_trash",
      onClick: async (id) => {
        const result = await fetch(`/api/events/${id}`, {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ isCancelled: false }),
        });
        if (!result.ok) {
          //TODO: handle error
        }
        setItems((previous) =>
          previous.map((item) =>
            item.id === id
              ? {
                  ...item,
                  data: { ...item.data, status: item.data.uncancelledStatus },
                }
              : item
          )
        );
        toastRef.current.show("Event restored successfully!", "success", () => {
          setSearchParams(prepareParams(currentParams, {}));
        });
      },
      condition: (item) => item.status === "Cancelled",
    },
  ];

  useEffect(() => {
    setItems(
      events.map((event) => ({
        id: event.code,
        title: event.name,
        data: event,
        selected: Math.random() > 0.5,
      }))
    );
  }, [events]);

  function searchChangeHandler(searchString) {
    setSearchParams(
      prepareParams(currentParams, {
        search: searchString,
        page: 1,
      })
    );
  }

  function filtersChangeHandler(filters) {
    const reducedFilters = filters.reduce(
      (filterParams, filter) => {
        filterParams[filter.param] = filter.values;
        return filterParams;
      },
      { page: 1 }
    );
    console.log(reducedFilters);
    setSearchParams(prepareParams(currentParams, reducedFilters));
  }

  // function filtersChangeHandler(filters) {
  //   const reducedFilters = filters.reduce(
  //     (filterParams, filter) => {
  //       const param = filterParams[filter.param];
  //       filterParams[filter.param] =
  //         ["startbefore", "endbefore"].indexOf(param) !== -1
  //           ? [`${filter.values[0]}T00:00:00.000`]
  //           : ["startafter", "endafter"].indexOf(param) !== -1
  //           ? [`${filter.values[0]}T23:59:59.999`]
  //           : filter.values;
  //       return filterParams;
  //     },
  //     { page: 1 }
  //   );
  //   console.log(reducedFilters);
  //   setSearchParams(prepareParams(currentParams, reducedFilters));
  // }

  function sortChangeHandler(sort, direction) {
    if (sort !== currentParams.sort || direction !== currentParams.direction) {
      setSearchParams(
        prepareParams(currentParams, {
          sort,
          direction,
        })
      );
    }
  }

  function paginationChangeHandler(page, size) {
    setSearchParams(prepareParams(currentParams, { page, size }));
  }

  function itemSelectionChangeHandler(id, value) {
    setItems((previous) =>
      previous.map((item) =>
        item.id === id ? { ...item, selected: value } : item
      )
    );
  }

  function allItemsSelectionChangeHandler(value) {
    setItems((previous) =>
      previous.map((item) => ({ ...item, selected: value }))
    );
  }

  return (
    <MasterPage breadcrumb={breadcrumb} title="">
      {/* <div className="row flex-sm-row-reverse g-2">
        <div className="col-12 col-sm-auto">
          <Link className="btn btn-primary w-100" to="/events/new">
            New Event
          </Link>
        </div>
      </div>
      <List items={items} fields={fields} /> */}
      <div className="vstack gap-3 m-3">
        <div className="hstack justify-content-md-center align-items-center position-relative">
          <h1 className="m-0">Events</h1>
          <div
            className={
              "hstack justify-content-end align-items-center " +
              "position-absolute w-100 h-100 pe-none"
            }
          >
            <Link className="btn btn-primary pe-auto" to="/events/new">
              <span
                className="material-symbols-rounded me-1 user-select-none"
                style={{
                  fontSize: "1.2em",
                  verticalAlign: "-21%",
                  fontVariationSettings: `'FILL' 1, 'wght' 500, 'GRAD' 0, 'opsz' 48`,
                }}
                aria-hidden="true"
              >
                add
              </span>
              Add Event
            </Link>
          </div>
        </div>
        <List
          className=""
          items={items}
          fields={fields}
          actions={actions}
          bulkActions={bulkActions}
          search={true}
          searchButton={true}
          searchValue={currentParams.search || ""}
          onSearch={searchChangeHandler}
          // onSearchChange={(value) => {
          //   console.log(value);
          // }}
          filters={filters}
          onFilterChange={filtersChangeHandler}
          selection={false}
          onItemSelectionChange={itemSelectionChangeHandler}
          onAllItemsSelectionChange={allItemsSelectionChangeHandler}
          sort={true}
          sortField={currentParams.sort}
          sortDirection={currentParams.direction}
          onSortChange={sortChangeHandler}
          pagination={true}
          page={currentParams.page}
          size={currentParams.size}
          totalCount={totalCount}
          allowedSizes={allowedSizes}
          onPaginationChange={paginationChangeHandler}
          itemLabel="event"
          itemLabelPlural="events"
          breakpoint="md"
          itemsContainerMinHeight="300px"
        />
      </div>
      <Tooltip
        id="venue-tooltip"
        className="rounded bg-white text-dark"
        style={{
          zIndex: 9999,
        }}
        clickable={true}
        // noArrow={true}
        delayShow={1000}
        border="1px solid #ccc"
        opacity={1}
      />
      {/* {items.map((item) => (
        <Tooltip
          key={item.id}
          id={`tooltip-${item.id}`}
          className="rounded bg-white text-dark"
          style={{
            zIndex: 9999,
          }}
          clickable={true}
          // noArrow={true}
          border="1px solid #ccc"
          opacity={1}
        />
      ))} */}
      {/* <Tooltip id="my-tooltip" className="" style={{ zIndex: 9999 }} /> */}
      {/* <div className="border p-3" style={{ width: '200px' }}>
        <FilterVenue />
      </div> */}
      <Toast ref={toastRef} />
    </MasterPage>
  );
};

export default Main;

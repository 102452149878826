import React from 'react';
import Select from '../Select';
import styles from './index.module.css';

const SelectPlus = (props) => {
  const { plusProps, width, disabled, ...innerProps } = props;
  return (
    <div
      className="hstack gap-1"
      style={{
        width: width ? width : '100%',
        minWidth: width ? width : '0',
      }}
    >
      <Select disabled={disabled} {...innerProps} />
      <button
        className={`btn btn-secondary ${styles.plus}`}
        type="button"
        disabled={disabled}
        {...plusProps}
      >
        <span
          className={`material-symbols-rounded d-block ${styles.icon}`}
          aria-hidden="true"
        >
          add
        </span>
      </button>
    </div>
  );
};

export default SelectPlus;

import React, { useRef, useState } from 'react';
import { Link, useLoaderData, useNavigate } from 'react-router-dom';
import MasterPage from '../../../components/MasterPage';
import Form from '../../../components/Form';
import List from '../../../components/List';
import Accordion from '../../../components/Accordion';
import Toast from '../../../components/Toast';

const pageSize = 10;

const View = (props) => {
  const navigate = useNavigate();

  const [{ event }, { attendees: attendeesBase, totalCount: totalCountBase }] =
    useLoaderData();

  const breadcrumb = [
    { label: 'Events', path: '/events?sort=start-date&direction=desc' },
    { label: event.code, path: `/events/${event.code}` },
    { label: 'Registration' },
  ];

  const [attendees, setAttendees] = useState(
    attendeesBase.map((attendee) => ({
      id: attendee.code,
      data: attendee,
      selected: false,
    }))
  );

  const attendeesFields = [
    {
      name: 'code',
      label: 'Code',
      getValue: (item) => <span className="text-nowrap">{item.code}</span>,
    },
    {
      name: 'fullname',
      label: 'Fullname',
      getValue: (item) => <span className="text-nowrap">{item.fullname}</span>,
    },
    {
      name: 'status',
      label: 'Status',
      getValue: (item) => (
        <span className="text-nowrap">
          <span
            className={`badge p-1 me-2 ${
              !item.registered
                ? 'bg-danger'
                : item.checkIn
                ? 'bg-success'
                : 'bg-info'
            } border rounded-circle`}
          >
            <span className="visually-hidden"></span>
          </span>
          {!item.registered
            ? 'Unregistered'
            : item.checkIn
            ? 'Checked In'
            : 'Registered'}
        </span>
      ),
    },
  ];

  const attendeesActions = [
    {
      label: 'Unregister',
      icon: 'clear',
      onClick: async (id) => {
        if (!attendees.find((attendee) => attendee.id === id).data.registered) {
          return;
        }
        const result = await fetch(
          `/api/events/${event.code}/attendees/${id}`,
          {
            method: 'DELETE',
          }
        );
        if (!result.ok) {
          //TODO Handle error
          return;
        }
        setAttendees((prev) =>
          prev.map((attendee) =>
            attendee.id === id
              ? {
                  ...attendee,
                  data: {
                    ...attendee.data,
                    registered: false,
                    checkIn: false,
                  },
                }
              : attendee
          )
        );
      },
      condition: (item) => item.registered,
    },
    {
      label: 'Register',
      icon: 'done',
      onClick: async (id) => {
        if (attendees.find((attendee) => attendee.id === id).data.registered) {
          return;
        }
        const result = await fetch(`/api/events/${event.code}/attendees`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            code: id,
            checkIn: false,
          }),
        });
        if (!result.ok) {
          //TODO Handle error
          return;
        }
        setAttendees((prev) =>
          prev.map((attendee) =>
            attendee.id === id
              ? {
                  ...attendee,
                  data: {
                    ...attendee.data,
                    registered: true,
                    checkIn: false,
                  },
                }
              : attendee
          )
        );
      },
      condition: (item) => !item.registered,
    },
    {
      label: 'Register & Check In',
      icon: 'done_all',
      onClick: async (id) => {
        if (attendees.find((attendee) => attendee.id === id).data.registered) {
          return;
        }
        const result = await fetch(`/api/events/${event.code}/attendees`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            code: id,
            checkIn: true,
          }),
        });
        if (!result.ok) {
          //TODO Handle error
          return;
        }
        setAttendees((prev) =>
          prev.map((attendee) =>
            attendee.id === id
              ? {
                  ...attendee,
                  data: {
                    ...attendee.data,
                    registered: true,
                    checkIn: true,
                  },
                }
              : attendee
          )
        );
      },
      condition: (item) => !item.registered,
    },
  ];

  const attendeesBulkActions = [
    {
      label: 'Register',
      onClick: async () => {
        const unregisteredAttendees = attendees.filter(
          (attendee) => attendee.selected && !attendee.data.registered
        );
        const results = await Promise.all(
          unregisteredAttendees.map((attendee) =>
            fetch(`/api/events/${event.code}/attendees`, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({
                code: attendee.data.code,
                checkIn: false,
              }),
            })
          )
        );
        const changedAttendees = {};
        results.forEach((result, index) => {
          if (!result.ok) {
            //TODO: Handle error
            return;
          }
          changedAttendees[unregisteredAttendees[index].id] = true;
        });
        setAttendees((prev) =>
          prev.map((attendee) =>
            changedAttendees[attendee.id]
              ? {
                  ...attendee,
                  data: {
                    ...attendee.data,
                    registered: true,
                  },
                  selected: false,
                }
              : {
                  ...attendee,
                  selected: false,
                }
          )
        );
      },
    },
    {
      label: 'Register & Check In',
      onClick: async () => {
        const unregisteredAttendees = attendees.filter(
          (attendee) => attendee.selected && !attendee.data.registered
        );
        const results = await Promise.all(
          unregisteredAttendees.map((attendee) =>
            fetch(`/api/events/${event.code}/attendees`, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({
                code: attendee.data.code,
                checkIn: true,
              }),
            })
          )
        );
        const changedAttendees = {};
        results.forEach((result, index) => {
          if (!result.ok) {
            //TODO: Handle error
            return;
          }
          changedAttendees[unregisteredAttendees[index].id] = true;
        });
        setAttendees((prev) =>
          prev.map((attendee) =>
            changedAttendees[attendee.id]
              ? {
                  ...attendee,
                  data: {
                    ...attendee.data,
                    registered: true,
                    checkIn: true,
                  },
                  selected: false,
                }
              : {
                  ...attendee,
                  selected: false,
                }
          )
        );
      },
    },
  ];

  const [search, setSearch] = useState('');

  const [currentPage, setCurrentPage] = useState(1);

  const [totalCount, setTotalCount] = useState(totalCountBase);

  const toastRef = useRef(null);

  return (
    <MasterPage breadcrumb={breadcrumb}>
      <div className="hstack justify-content-center">
        <div
          className="vstack align-items-center w-100 gap-3 p-3"
          style={{ maxWidth: '800px' }}
        >
          <h1>{`${event.name} - Registration`}</h1>
          <List
            className=""
            items={attendees}
            fields={attendeesFields}
            search={true}
            searchValue={search}
            searchButton={true}
            onSearch={async (searchString) => {
              console.log(searchString);
              let result = await fetch(
                `/api/events/${event.code}/attendees?page=${currentPage}&size=${pageSize}&search=${searchString}&count=true`
              );
              if (!result.ok) {
                // TODO: Handle error
                return;
              }
              let data = await result.json();
              if (data.attendees.length === 0 && data.totalCount > 0) {
                const lastPage = Math.ceil(data.totalCount / pageSize);
                result = await fetch(
                  `/api/events/${event.code}/attendees?page=${lastPage}&size=${pageSize}&search=${searchString}&count=true`
                );
                if (!result.ok) {
                  // TODO: Handle error
                  return;
                }
                setCurrentPage(lastPage);
                data = await result.json();
              }
              setSearch(searchString);
              setAttendees(
                data.attendees.map((attendee) => ({
                  id: attendee.code,
                  data: attendee,
                  selected: false,
                }))
              );
              setTotalCount(data.totalCount);
            }}
            selection={true}
            onItemSelectionChange={(id, selected) => {
              setAttendees((prev) =>
                prev.map((attendee) =>
                  attendee.id === id
                    ? {
                        ...attendee,
                        selected: selected,
                      }
                    : attendee
                )
              );
            }}
            onAllItemsSelectionChange={(selected) => {
              setAttendees((prev) =>
                prev.map((attendee) => ({
                  ...attendee,
                  selected: selected,
                }))
              );
            }}
            actions={attendeesActions}
            bulkActions={attendeesBulkActions}
            pagination={true}
            page={currentPage}
            size={pageSize}
            totalCount={totalCount}
            allowedSizes={[pageSize]}
            onPaginationChange={async (page, size) => {
              console.log(page, size);
              let result = await fetch(
                `/api/events/${event.code}/attendees?page=${page}&size=${pageSize}&search=${search}`
              );
              if (!result.ok) {
                // TODO: Handle error
                return;
              }
              let data = await result.json();
              setCurrentPage(page);
              setAttendees(
                data.attendees.map((attendee) => ({
                  id: attendee.code,
                  data: attendee,
                  selected: false,
                }))
              );
            }}
            itemLabel=""
            itemLabelPlural=""
            hideSummary={true}
          />
        </div>
      </div>
      <Toast ref={toastRef} />
    </MasterPage>
  );
};

export default View;

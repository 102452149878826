import React, {
  Fragment,
  useState,
  useEffect,
  useContext,
  useRef,
} from 'react';
import { useNavigate } from 'react-router-dom';
import AuthContext from '../../store/auth-context';
import LoginForm from './components/LoginForm';
import styles from './index.module.css';

const emailRegex = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;

const Login = (props) => {
  const navigate = useNavigate();

  const [hiddenAlert, setHiddenAlert] = useState(true);

  const formRef = useRef(null);

  const authCtx = useContext(AuthContext);

  // useEffect(() => {
  //   authCtx.logOut();
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  async function submitHandler(email, password) {
    if (email === '' || !emailRegex.test(email)) {
      setHiddenAlert(false);
      formRef.current.focusEmail();
      return;
    }
    if (password === '') {
      setHiddenAlert(false);
      formRef.current.focusPassword();
      return;
    }
    const loggedIn = await authCtx.logIn(email, password);
    if (loggedIn) {
      navigate('/');
    } else {
      setHiddenAlert(false);
    }
  }

  return (
    <div
      className={`hstack justify-content-center align-items-center vw-100 min-vh-100 ${styles['outer-container']}`}
    >
      <div
        className={`rounded bg-white p-3 p-sm-5 ${styles['inner-container']}`}
      >
        <div className="vstack">
          <div className="hstack justify-content-center p-3 pb-0">
            <img
              style={{ width: '120px', maxWidth: '100%' }}
              src="/logo.svg"
              alt="bld logo"
            />
          </div>
          <h1 className="text-center mb-3" style={{ fontSize: '1.5em' }}>
            BLD Management
          </h1>
          <LoginForm
            ref={formRef}
            hiddenAlert={hiddenAlert}
            onSubmit={submitHandler}
          />
        </div>
      </div>
    </div>
  );
};

export default Login;

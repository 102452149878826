import React, {
  useImperativeHandle,
  forwardRef,
  useState,
  useCallback,
} from 'react';

import ToastMessage from './components/ToastMessage';

const Toast = forwardRef((props, ref) => {
  const [toasts, setToasts] = useState([]);
  const [nextId, setNextId] = useState(1);

  useImperativeHandle(ref, () => ({
    show: (message, variant, onClose) => {
      setToasts((previous) => [
        ...previous,
        { id: nextId, message, variant: variant || 'info', onClose },
      ]);
      setNextId((previous) => previous + 1);
    },
  }));

  const toastCloseHandler = useCallback((toast) => {
    return () => {
      setToasts((previous) => previous.filter((item) => item.id !== toast.id));
      if (toast.onClose) {
        toast.onClose();
      }
    };
  }, []);

  return (
    <div
      className={
        'vstack justify-content-end align-items-center gap-3 position-fixed ' +
        'vw-100 vh-100 p-3 pe-none'
      }
      style={{ top: '0', left: '0', zIndex: 9999 }}
      aria-live="polite"
      aria-atomic="true"
    >
      {toasts.map((toast) => (
        <ToastMessage
          key={toast.id}
          message={toast.message}
          variant={toast.variant}
          onClose={toastCloseHandler(toast)}
        />
      ))}
    </div>
  );
});

export default Toast;

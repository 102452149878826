import { ReactComponent as EventsIcon } from '../../assets/images/icons/events.svg';
import { ReactComponent as UsersIcon } from '../../assets/images/icons/users.svg';
import { ReactComponent as MembersIcon } from '../../assets/images/icons/members.svg';
import { ReactComponent as VenuesIcon } from '../../assets/images/icons/venues.svg';
import { ReactComponent as ReportsIcon } from '../../assets/images/icons/reports.svg';

const homeItems = [
  {
    title: 'Events',
    icon: 'calendar_month',
    links: [
      { label: 'Manage', url: '/events?sort=start-date&direction=desc' },
      { label: 'Add', url: '/events/new' },
    ],
    needsAuthorization: [1, 2],
  },
  {
    title: 'Members',
    icon: 'person',
    links: [
      { label: 'Manage', url: '/members' },
      { label: 'Add', url: '/members/new' },
    ],
    needsAuthorization: [1, 2],
  },
  {
    title: 'Programs',
    icon: 'overview',
    links: [
      { label: 'Manage', url: '/programs' },
      { label: 'Add', url: '/programs/new' },
    ],
    needsAuthorization: [1],
  },
  {
    title: 'Services',
    icon: 'work_history',
    links: [
      { label: 'Manage', url: '/services' },
      { label: 'Add', url: '/services/new' },
    ],
    needsAuthorization: [1],
  },
  {
    title: 'Venues',
    icon: 'location_on',
    links: [
      { label: 'Manage', url: '/venues' },
      { label: 'Add', url: '/venues/new' },
    ],
    needsAuthorization: [1],
  },
  {
    title: 'Reports',
    icon: 'insert_chart',
    links: [{ label: 'View', url: '/reports' }],
    needsAuthorization: [1],
  },
];

export default homeItems;

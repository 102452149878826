import React, { useRef } from 'react';
import styles from './index.module.css';

const TextArea = (props) => {
  const { isValid, wasValidated, width, disabled, ...innerProps } = props;
  const ref = useRef(null);

  function containerClickHandler(event) {
    if (event.target !== ref.current) {
      ref.current.focus();
    }
  }

  return (
    <div
      className={`border rounded bg-${
        disabled ? 'body-tertiary' : 'white'
      } d-flex align-items-start gap-2 ${styles.container} ${
        wasValidated ? (isValid ? styles.valid : styles.invalid) : ''
      }`}
      onClick={containerClickHandler}
      style={{ width: width ? width : '100%', minWidth: width ? width : '0' }}
    >
      <textarea
        ref={ref}
        className={`flex-grow-1 text-${disabled ? 'body-secondary' : 'dark'} ${
          styles['text-area']
        }`}
        disabled={disabled}
        {...innerProps}
      />
      {wasValidated && (
        <span
          className={`material-symbols-rounded text-${
            isValid ? 'success' : 'danger'
          } user-select-none ${styles.icon}`}
          aria-hidden="true"
        >
          {isValid ? 'check_circle' : 'error'}
        </span>
      )}
    </div>
  );
};

export default TextArea;

import React from 'react';

const FormAction = (props) => {
  const { breakpoint, variant, label, icon, ...innerProps } = props;
  return (
    <div className={breakpoint ? `col-12 col-${breakpoint}-auto` : 'col-12'}>
      <button
        className={`btn btn-${variant ? variant : 'primary'} w-100`}
        {...innerProps}
      >
        {icon && (
          <span
            className="material-symbols-rounded user-selected-none me-2"
            style={{
              fontSize: '1rem',
              fontVariationSettings:
                "'FILL' 1, 'wght' 400, 'GRAD' 0, 'opsz' 20",
              verticalAlign: '-15%',
            }}
            aria-hidden="true"
          >
            {icon}
          </span>
        )}
        {label}
      </button>
    </div>
  );
};

export default FormAction;

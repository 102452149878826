import React, { useId } from 'react';

const Accordion = (props) => {
  const id = useId();

  return (
    <div id={id} className={`position-relative accordion ${props.className}`}>
      {Array.isArray(props.sections) &&
        props.sections.length > 0 &&
        props.sections.map((section, index) => (
          <div className="accordion-item" key={index}>
            <h2 className="accordion-header">
              <button
                className={`accordion-button ${index ? 'collapsed' : ''}`}
                type="button"
                data-bs-toggle="collapse"
                data-bs-target={`#${id}-collapse-${index}`}
                aria-expanded={!index}
                aria-controls={`${id}-collapse-${index}`}
              >
                {section.title}
              </button>
            </h2>
            <div
              id={`${id}-collapse-${index}`}
              className={`accordion-collapse collapse ${index ? '' : 'show'}`}
              data-bs-parent={`#${id}`}
            >
              <div className="accordion-body">{section.content}</div>
            </div>
          </div>
        ))}
    </div>
  );
};

export default Accordion;

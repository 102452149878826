import React, {
  forwardRef,
  useId,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import Input from '../../../../components/UI/Input';
import InputPassword from '../../../../components/UI/InputPassword';

const LoginForm = forwardRef((props, ref) => {
  const id = useId();
  const emailId = `${id}-email`;
  const passwordId = `${id}-password`;

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const emailRef = useRef(null);
  const passwordRef = useRef(null);

  useImperativeHandle(ref, () => ({
    focusEmail: () => {
      emailRef.current.focus();
    },
    focusPassword: () => {
      passwordRef.current.focus();
    },
  }));

  function emailChangeHandler(event) {
    const value = event.target.value;
    setEmail(value);
  }

  function passwordChangeHandler(event) {
    const value = event.target.value;
    setPassword(value);
  }

  function submitHandler(event) {
    event.preventDefault();
    if (props.onSubmit) {
      props.onSubmit(email.trim(), password.trim());
    }
  }

  return (
    <form className="" onSubmit={submitHandler} noValidate>
      {!props.hiddenAlert && (
        <div className="alert alert-danger mb-3" role="alert">
          Wrong email or password.
        </div>
      )}
      <div className="mb-3">
        <label className="form-label ms-1 mb-1" htmlFor={emailId}>
          Email
        </label>
        <Input
          ref={emailRef}
          id={emailId}
          type="text"
          autoComplete="username"
          value={email}
          onChange={emailChangeHandler}
        />
      </div>
      <div className="mb-4">
        <label className="form-label ms-1 mb-1" htmlFor={passwordId}>
          Password
        </label>
        <InputPassword
          ref={passwordRef}
          id={passwordId}
          autoComplete="current-password"
          revealButton={true}
          value={password}
          onChange={passwordChangeHandler}
        />
      </div>
      <button
        className="btn btn-primary w-100"
        type="submit"
        onSubmit={(event) => {
          event.preventDefault();
        }}
      >
        LOGIN
      </button>
    </form>
  );
});

export default LoginForm;

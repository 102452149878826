import React, { useRef } from 'react';
import { useLoaderData, useNavigate } from 'react-router-dom';
import MasterPage from '../../../components/MasterPage';
import Form from '../../../components/Form';
import Toast from '../../../components/Toast';

const breadcrumb = [{ label: 'Programs', path: '/programs' }, { label: 'New' }];

const Create = (props) => {
  const navigate = useNavigate();

  const [{ programCategories }] = useLoaderData();

  const toastRef = useRef(null);

  const formRef = useRef(null);
  const nameRef = useRef(null);
  const descriptionRef = useRef(null);
  const categoryRef = useRef(null);

  const formProps = {
    id: 'member-form',
    className: 'border rounded px-3 py-3 py-md-5',
    ref: formRef,
    labelContainerSize: { md: 2 },
    items: [
      {
        id: 'category',
        ref: categoryRef,
        name: 'categoryId',
        label: 'Category',
        type: 'select',
        value: '',
        options: programCategories.map((category) => ({
          value: `${category.id}`,
          label: category.name,
        })),
        validations: [
          {
            validate: (value) => value.length > 0,
            message: 'Category is required.',
          },
        ],
        size: { xs: 12 },
        required: true,
      },
      {
        id: 'name',
        ref: nameRef,
        name: 'name',
        label: 'Name',
        type: 'text',
        value: '',
        validations: [
          //TODO - add validation for unique name
          {
            validate: (value) => value.length > 0,
            message: 'Name is required.',
          },
        ],
        size: { xs: 12 },
        required: true,
      },
      {
        id: 'description',
        ref: descriptionRef,
        name: 'description',
        label: 'Description',
        type: 'area',
        value: '',
        size: { xs: 12 },
      },
    ],
    actions: [
      {
        label: 'Save',
        type: 'submit',
        variant: 'primary',
      },
      {
        label: 'Cancel',
        type: 'button',
        variant: 'secondary',
        onClick: () => {
          navigate('/programs');
        },
      },
    ],
    maxWidth: '800px',
    layoutBreakpoint: 'md',
    actionsBreakpoint: 'sm',
    onSubmit: async (event) => {
      event.preventDefault();
      const isValid = await formRef.current.validate();
      if (!isValid) {
        toastRef.current.show(
          'Program create failed! Please check for errors.',
          'danger'
        );
        return;
      }
      const data = formRef.current.getData();
      const result = await fetch('/api/programs', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });
      if (result.ok) {
        formRef.current.reset();
        toastRef.current.show('Program created successfully!', 'success');
        return;
      }
      toastRef.current.show(
        'Program create failed! Please try again.',
        'danger'
      );
    },
  };

  return (
    <MasterPage breadcrumb={breadcrumb}>
      <div className="vstack gap-3 m-3" style={{}}>
        <div className="hstack justify-content-md-center align-items-center position-relative">
          <h1>New Program</h1>
        </div>
        <div className="hstack justify-content-center">
          <Form {...formProps} />
        </div>
      </div>
      <Toast ref={toastRef} />
    </MasterPage>
  );
};

export default Create;

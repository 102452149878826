import Main from '../pages/Programs/Main';
import Create from '../pages/Programs/Create';
import View from '../pages/Programs/View';
import Edit from '../pages/Programs/Edit';

const routes = [
  {
    path: '/programs',
    element: <Main />,
    loader: ({ params, request }) => {
      const url = new URL(request.url);
      const searchParamsString = url.searchParams.toString();
      const apiUrl =
        searchParamsString.length === 0
          ? '/api/programs'
          : `/api/programs?${searchParamsString}`;
      return Promise.all([
        // fetch('/api/roles').then((result) => result.json()),
        fetch(apiUrl).then((result) => result.json()),
      ]);
    },
  },
  {
    path: '/programs/new',
    element: <Create />,
    loader: ({ params, request }) => {
      return Promise.all([
        // fetch('/api/roles').then((result) => result.json()),
        fetch('/api/program-categories').then((result) => result.json()),
      ]);
    },
  },
  {
    path: '/programs/:code',
    element: <View />,
    loader: ({ params, request }) => {
      return fetch(`/api/programs/${params.code}`).then((result) =>
        result.json()
      );
    },
  },
  {
    path: '/programs/:code/edit',
    element: <Edit />,
    loader: ({ params, request }) => {
      return Promise.all([
        fetch(`/api/programs/${params.code}`).then((result) => result.json()),
        fetch('/api/program-categories').then((result) => result.json()),
        // fetch('/api/roles').then((result) => result.json()),
        // fetch('/api/civil-statuses').then((result) => result.json()),
      ]);
    },
  },
];

export default routes;

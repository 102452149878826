import React from 'react';
import MasterPage from '../../../components/MasterPage';

const code = 47;

const Edit = (props) => {
  const breadcrumb = [
    { label: 'Services', path: '/services' },
    { label: code, path: `/services/${code}` },
    { label: 'Edit' },
  ];

  return (
    <MasterPage breadcrumb={breadcrumb}>
      <div className="border rounded m-3 p-3" style={{}}>
        Services Edit
      </div>
    </MasterPage>
  );
};

export default Edit;

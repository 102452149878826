import React from 'react';

const AuthContext = React.createContext({
  code: '',
  firstName: '',
  role: '',
  roleId: 1,
  token: '',
  logIn(email, password) {},
  logOut() {},
});

export default AuthContext;

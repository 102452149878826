import React, { useContext, useRef } from 'react';
// import AuthContext from '../../store/auth-context';
// import MasterPage from '../../components/MasterPage/MasterPage';
// import Form from '../../components/Form';
// import ToastContainer from '../../components/ToastMessage/ToastContainer';
// import PasswordValidation from '../../components/PasswordValidation';
import AuthContext from '../../store/auth-context';
import MasterPage from '../../components/MasterPage';
import Toast from '../../components/Toast';
import Form from '../../components/Form';
// import PasswordValidation from '../../components/PasswordValidation';

const passwordCharacterPattern = /[!-~]/;
const lowerRegex = /[a-z]/;
const upperRegex = /[A-Z]/;
const digitRegex = /[0-9]/;
const specialRegex = /[^a-zA-Z0-9]/;
const breadcrumb = [{ label: 'Change Password' }];

const ChangePassword = () => {
  const toastRef = useRef(null);
  const formRef = useRef(null);
  const currentPasswordRef = useRef(null);
  const newPasswordRef = useRef(null);
  const confirmPasswordRef = useRef(null);
  const items = [
    {
      id: 'current-password',
      label: 'Current Password',
      fields: [
        {
          ref: currentPasswordRef,
          name: 'current-password',
          type: 'password',
          placeholder: 'Current Password',
          defaultValue: '',
          validations: [
            {
              id: 'required',
              validate: (value) => value.length > 0,
              message: 'Current password is required.',
            },
          ],
          limitLength: 50,
        },
      ],
    },
    {
      id: 'new-password',
      label: 'New Password',
      fields: [
        {
          ref: newPasswordRef,
          name: 'new-password',
          type: 'password',
          placeholder: 'New Password',
          defaultValue: '',
          validations: [
            {
              id: 'required',
              validate: (value) => value.length > 0,
              message: 'New password is required.',
            },
            {
              id: 'length',
              validate: (value) => value.length >= 8,
              message: 'New password must have at least 8 characters.',
            },
            {
              id: 'lower',
              validate: (value) => lowerRegex.test(value),
              message: 'New password must have at least one lowercase letter.',
            },
            {
              id: 'upper',
              validate: (value) => upperRegex.test(value),
              message: 'New password must have at least one uppercase letter.',
            },
            {
              id: 'digit',
              validate: (value) => digitRegex.test(value),
              message: 'Password must have at least one digit.',
            },
            {
              id: 'special',
              validate: (value) => specialRegex.test(value),
              message: 'New password must have at least one special character.',
            },
          ],
          hiddenAlerts: true,
          limitLength: 50,
          characterPattern: passwordCharacterPattern,
          //   innerComponent: PasswordValidation,
        },
      ],
    },
    {
      id: 'confirm-password',
      label: 'Confirm Password',
      fields: [
        {
          ref: confirmPasswordRef,
          name: 'confirm-password',
          type: 'password',
          placeholder: 'Confirm Password',
          defaultValue: '',
          validations: [
            {
              id: 'required',
              validate: (value) => value.length > 0,
              message: 'Confirm password is required.',
            },
            {
              id: 'match',
              validate: (value) => value === newPasswordRef.current.getValue(),
              message: 'Must match the new password.',
            },
          ],
          limitLength: 50,
        },
      ],
    },
  ];
  const authCtx = useContext(AuthContext);
  const { code } = authCtx;

  async function submitHandler(event) {
    event.preventDefault();
    const isValid = await formRef.current.validate();
    if (isValid) {
      const currentPassword = currentPasswordRef.current.getValue();
      const newPassword = newPasswordRef.current.getValue();
      try {
        const response = await fetch('/api/change-password', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            code,
            currentPassword,
            newPassword,
          }),
        });
        if (response.status === 200) {
          toastRef.current.showToast(
            'Pssword changed successfully!',
            'success'
          );
          formRef.current.clear();
          return;
        } else {
          const data = await response.json();
          toastRef.current.showToast(
            `Password changed failed! ${data.message}.`,
            'danger'
          );
        }
      } catch (error) {
        console.log('ERROR', error);
      }
    } else {
      toastRef.current.showToast(
        'Password changed failed! Please check for errors.',
        'danger'
      );
    }
  }

  return (
    <MasterPage breadcrumb={breadcrumb}>
      <div className="hstack justify-content-center">
        <div
          style={{
            maxWidth: '500px',
          }}
        >
          <div className="hstack mb-3 justify-content-center align-items-center">
            <h1 className="m-0 mx-3 cursor-default">Change Password</h1>
          </div>
          <Form
            className="border rounded p-3 mx-3"
            ref={formRef}
            items={items}
            onSubmit={submitHandler}
          />
        </div>
      </div>
      <Toast ref={toastRef} />
    </MasterPage>
  );
};

export default ChangePassword;

import Main from '../pages/Reports/Main';
import Create from '../pages/Reports/Create';
import View from '../pages/Reports/View';
import Edit from '../pages/Reports/Edit';

const routes = [
  {
    path: '/reports',
    element: <Main />,
    loader: ({ params, request }) => {
      return null;
    },
  },
  {
    path: '/reports/create',
    element: <Create />,
    loader: ({ params, request }) => {
      return null;
    },
  },
  {
    path: '/reports/:code',
    element: <View />,
    loader: ({ params, request }) => {
      return null;
    },
  },
  {
    path: '/reports/:code/edit',
    element: <Edit />,
    loader: ({ params, request }) => {
      return null;
    },
  },
];

export default routes;

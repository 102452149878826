import React, {
  forwardRef,
  useEffect,
  useId,
  useImperativeHandle,
} from 'react';

const FilterCheckbox = forwardRef((props, ref) => {
  const id = useId();

  const [values, setValues] = React.useState(props.options);

  useEffect(() => {
    setValues(props.options);
  }, [props.options]);

  function checkboxChangeHandler(index) {
    return (event) => {
      setValues((previous) =>
        previous.map((item, i) =>
          i === index ? { ...item, selected: event.target.checked } : item
        )
      );
    };
  }

  useImperativeHandle(ref, () => ({
    getActiveFilters: () => ({
      param: props.param,
      values: values.filter((item) => item.selected).map((item) => item.value),
    }),
  }));

  return (
    <form className="dropdown-menu px-3 w-100 w-sm-auto">
      {values.map((option, index) => (
        <div key={index} className="form-check my-2">
          <input
            id={`${id}-${index}`}
            type="checkbox"
            className="form-check-input"
            checked={option.selected}
            onChange={checkboxChangeHandler(index)}
          />
          <label className="form-check-label" htmlFor={`${id}-${index}`}>
            {option.label}
          </label>
        </div>
      ))}
    </form>
  );
});

export default FilterCheckbox;

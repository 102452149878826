import React from 'react';
import MasterPage from '../../../components/MasterPage';

const breadcrumb = [{ label: 'Reports' }];

const Main = (props) => {
  return (
    <MasterPage breadcrumb={breadcrumb}>
      <div className="border rounded m-3 p-3" style={{}}>
        Reports
      </div>
    </MasterPage>
  );
};

export default Main;

import React from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import styles from './index.module.css';

function isMatched(baseUrl, url) {
  return baseUrl === url || url.indexOf(`${baseUrl}/`) === 0;
}

const NavItem = (props) => {
  const location = useLocation();

  return (
    <li className="nav-item mx-xl-1 mb-2 mb-lg-0">
      <NavLink
        className={({ isActive, isPending }) =>
          `nav-link px-3 py-2 px-lg-2 px-xxl-3 rounded-top ${
            isActive
              ? `bg-primary bg-opacity-75 text-white ${styles.active}`
              : isPending
              ? 'bg-white bg-opacity-10 text-white'
              : ''
          } ${styles['nav-link']}`
        }
        to={props.path}
      >
        <span
          className="material-symbols-rounded me-2 d-lg-none user-select-none"
          style={{
            fontVariationSettings: `'FILL' ${
              isMatched(props.path, location.pathname) ? 1 : 0
            }, 'wght' 400, 'GRAD' 0, 'opsz' 48`,
            fontSize: '1.5em',
            verticalAlign: '-20%',
          }}
          aria-hidden="true"
        >
          {props.icon}
        </span>
        {props.name}
      </NavLink>
    </li>
  );
};

export default NavItem;

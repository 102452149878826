import React, { forwardRef, useImperativeHandle, useRef } from 'react';
import Form from '../Form';

const ModalForm = forwardRef((props, ref) => {
  const {
    id,
    formId,
    title,
    actions,
    onClose,
    layoutBreakpoint,
    actionsBreakpoint,
    ...innerProps
  } = props;

  const formRef = useRef(null);
  const modalRef = useRef(null);

  useImperativeHandle(ref, () => ({
    getData: () => {
      return formRef.current.getData();
    },
    validate: async () => {
      const result = await formRef.current.validate();
      return result;
    },
    reset: () => {
      formRef.current.reset();
    },
  }));

  function fadeClickHandler(event) {
    if (event.target !== modalRef.current) {
      event.stopPropagation();
      return;
    }
    if (onClose) {
      onClose();
    }
  }

  return (
    <div
      ref={modalRef}
      className="modal fade"
      id={id}
      tabIndex="-1"
      aria-labelledby={`${id}-label`}
      aria-hidden="true"
      // data-bs-backdrop="false"
      onClick={fadeClickHandler}
    >
      <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
        <div className="modal-content">
          <div className="modal-header">
            <div className="hstack justify-content-center w-100">
              <h1 className="modal-title fs-5" id={`${id}-label`}>
                {title}
              </h1>
            </div>
          </div>
          <div className="modal-body">
            <Form
              ref={formRef}
              id={formId}
              layoutBreakpoint={layoutBreakpoint}
              {...innerProps}
            />
          </div>
          <div className="modal-footer px-0">
            <div className="row w-100">
              <div
                className={`col-12 offset-${layoutBreakpoint}-1 col-${layoutBreakpoint}-10`}
              >
                <div className="row g-2 flex-sm-row-reverse">
                  {actions.map((action, index) => {
                    const { label, variant, ...other } = action;
                    return (
                      <div
                        key={index}
                        className={`col-12 col-${actionsBreakpoint}-auto`}
                      >
                        <button
                          className={`btn btn-${variant} w-100 w-sm-auto`}
                          {...other}
                        >
                          {label}
                        </button>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});

export default ModalForm;

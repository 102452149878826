const navItems = [
  {
    name: 'Home',
    path: '/',
    icon: 'home',
  },
  {
    name: 'Events',
    path: '/events?sort=start-date&direction=desc',
    icon: 'calendar_month',
  },
  {
    name: 'Members',
    path: '/members',
    icon: 'person',
  },
  {
    name: 'Programs',
    path: '/programs',
    icon: 'overview',
  },
  {
    name: 'Services',
    path: '/services',
    icon: 'work_history',
  },
  {
    name: 'Venues',
    path: '/venues',
    icon: 'location_on',
  },
  {
    name: 'Reports',
    path: '/reports',
    icon: 'insert_chart',
  },
];

export default navItems;

import React from 'react';
import PaginationNavigation from './components/PaginationNavigation';
import PaginationSummary from './components/PaginationSummary';

const Pagination = (props) => {
  return (
    <nav
      className="container-fluid px-2 py-0 text-nowrap"
      role="navigation"
      aria-label="Pagination Navigation"
    >
      <div
        className={`row justify-content-center ${
          props.hideSummary ? '' : 'justify-content-sm-between'
        } align-items-center`}
      >
        {!props.hideSummary && (
          <div className="col-auto d-none d-sm-block">
            <PaginationSummary {...props} />
          </div>
        )}
        <div className="col-auto">
          <PaginationNavigation {...props} />
        </div>
      </div>
    </nav>
  );
};

export default Pagination;

import React, { useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import AuthContext from '../../../../../../store/auth-context';

const UserDropdown = (props) => {
  const navigate = useNavigate();

  const authCtx = useContext(AuthContext);
  const firstName = authCtx.code ? authCtx.firstName.split(' ')[0] : '';
  const initials = authCtx.code
    ? `${authCtx.firstName[0]}${authCtx.lastName[0]}`
    : '';

  function logoutButtonClickHandler() {
    authCtx.logOut();
    navigate('/login');
  }

  return (
    <div className="dropdown ms-3">
      <button
        className="btn btn-primary border-0 rounded-pill p-0"
        type="button"
        data-bs-toggle="dropdown"
        aria-expanded="false"
      >
        <div className="hstack" style={{ height: '40px', padding: '2px' }}>
          <div
            className="vstack justify-content-center rounded-circle bg-white text-primary me-xl-2"
            style={{ width: '36px', height: '36px' }}
          >
            {initials}
          </div>
          <span className="d-none d-xl-inline">{firstName}</span>
          <span
            className="material-symbols-rounded user-select-none d-none d-sm-inline"
            style={{
              fontSize: '2rem',
            }}
            aria-hidden="true"
          >
            arrow_drop_down
          </span>
        </div>
      </button>
      <ul className="dropdown-menu dropdown-menu-end m-0">
        <li>
          <Link className="dropdown-item" to="/profile">
            <span
              className="material-symbols-rounded user-select-none me-2"
              style={{
                fontSize: '1em',
                verticalAlign: '-15%',
                fontVariationSettings: `'FILL' 1, 'wght' 500, 'GRAD' 0, 'opsz' 20`,
              }}
              aria-hidden="true"
            >
              account_circle
            </span>
            Profile
          </Link>
        </li>
        <li>
          <Link className="dropdown-item" to="/profile/change-password">
            <span
              className="material-symbols-rounded user-select-none me-2"
              style={{
                fontSize: '1em',
                verticalAlign: '-15%',
                fontVariationSettings: `'FILL' 1, 'wght' 500, 'GRAD' 0, 'opsz' 20`,
              }}
              aria-hidden="true"
            >
              lock_reset
            </span>
            Change Password
          </Link>
        </li>
        <li>
          <hr className="dropdown-divider" />
        </li>
        <li>
          <button
            className="dropdown-item"
            type="button"
            onClick={logoutButtonClickHandler}
          >
            <span
              className="material-symbols-rounded user-select-none me-2"
              style={{
                fontSize: '1em',
                verticalAlign: '-15%',
                fontVariationSettings: `'FILL' 1, 'wght' 500, 'GRAD' 0, 'opsz' 20`,
              }}
              aria-hidden="true"
            >
              logout
            </span>
            Logout
          </button>
        </li>
      </ul>
    </div>
  );
};

export default UserDropdown;

import React, { useContext } from 'react';
// import AuthContext from '../../store/auth-context';
import MasterPage from '../../components/MasterPage';
import ItemCard from './ItemCard';
import homeItems from './homeItems';

const breadcrumb = [];

const Home = () => {
  // const authCtx = useContext(AuthContext);
  // const roleId = authCtx.roleId;
  return (
    <MasterPage breadcrumb={breadcrumb}>
      <div className="row row-cols-1 row-cols-sm-2 row-cols-lg-3 g-3 p-3">
        {homeItems
          // .filter(
          //   (item) =>
          //     item.needsAuthorization.length === 0 ||
          //     item.needsAuthorization.indexOf(roleId) !== -1
          // )
          .map((item) => (
            <div key={item.title} className="col">
              <ItemCard data={item} />
            </div>
          ))}
      </div>
    </MasterPage>
  );
};

export default Home;

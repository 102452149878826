import React, { useRef, useState } from "react";
import { Link, useLoaderData, useNavigate } from "react-router-dom";
import MasterPage from "../../../components/MasterPage";
import Form from "../../../components/Form";
import List from "../../../components/List";
import Accordion from "../../../components/Accordion";
import Toast from "../../../components/Toast";

const pageSize = 10;

const View = (props) => {
  const navigate = useNavigate();

  const [{ event }, { attendees: attendeesBase, totalCount: totalCountBase }] =
    useLoaderData();

  const breadcrumb = [
    { label: "Events", path: "/events" },
    { label: event.code, path: `/events/${event.code}` },
  ];

  const [attendees, setAttendees] = useState(
    attendeesBase.map((attendee) => ({
      id: attendee.code,
      data: attendee,
      selected: false,
    }))
  );

  const attendeesFields = [
    {
      name: "code",
      label: "Code",
      getValue: (item) => <span className="text-nowrap">{item.code}</span>,
    },
    {
      name: "fullname",
      label: "Fullname",
      getValue: (item) => <span className="text-nowrap">{item.fullname}</span>,
    },
    {
      name: "check-in",
      label: "Check-In",
      getValue: (item) => (
        <div className="form-check form-switch form-check-inline mx-0">
          <input
            className="form-check-input"
            type="checkbox"
            role="switch"
            checked={item.checkIn}
            onChange={async () => {
              const result = await fetch(
                `/api/events/${event.code}/attendees/${item.code}`,
                {
                  method: "PUT",
                  headers: {
                    "Content-Type": "application/json",
                  },
                  body: JSON.stringify({
                    checkIn: !item.checkIn,
                  }),
                }
              );
              if (!result.ok) {
                // TODO: Handle error
                return;
              }
              setAttendees((prev) =>
                prev.map((attendee) =>
                  attendee.id === item.code
                    ? {
                        ...attendee,
                        data: {
                          ...attendee.data,
                          checkIn: !attendee.data.checkIn,
                        },
                      }
                    : attendee
                )
              );
              console.log("Attendee updated");
            }}
          />
        </div>
      ),
      fit: true,
      align: "center",
    },
  ];

  const [search, setSearch] = useState("");

  const [currentPage, setCurrentPage] = useState(1);

  const [totalCount, setTotalCount] = useState(totalCountBase);

  const toastRef = useRef(null);

  const formRef = useRef(null);
  const codeRef = useRef(null);
  const nameRef = useRef(null);
  const descriptionRef = useRef(null);
  const startDateRef = useRef(null);
  const endDateRef = useRef(null);
  const startTimeRef = useRef(null);
  const endTimeRef = useRef(null);
  const registrationRequiredRef = useRef(null);
  const publicRef = useRef(null);
  const venueRef = useRef(null);
  const programRef = useRef(null);
  const categoryRef = useRef(null);
  const cancelledRef = useRef(null);

  const detailsFormProps = {
    id: "event-form",
    className: "w-100",
    ref: formRef,
    labelContainerSize: { md: 2 },
    items: [
      {
        id: "code",
        ref: codeRef,
        name: "code",
        label: "Code",
        type: "text",
        value: `${event.code}`,
        size: { xs: 12, md: "6" },
        disabled: true,
      },
      {
        id: "program",
        ref: programRef,
        name: "program",
        label: "Program",
        type: "text",
        value: event.program,
        size: { xs: 12, md: "8" },
        disabled: true,
      },
      {
        id: "category",
        ref: categoryRef,
        name: "category",
        label: "Category",
        type: "text",
        value: event.category,
        size: { xs: 12, md: "8" },
        disabled: true,
      },
      {
        id: "name",
        ref: nameRef,
        name: "name",
        label: "Name",
        type: "text",
        value: event.name,
        size: { xs: 12 },
        disabled: true,
      },
      {
        id: "description",
        ref: descriptionRef,
        name: "description",
        label: "Description",
        type: "area",
        value: event.description,
        size: { xs: 12 },
        disabled: true,
      },
      {
        id: "venue",
        ref: venueRef,
        name: "venue",
        label: "Venue",
        type: "text",
        value: event.venue,
        size: { xs: 12, md: "8" },
        disabled: true,
      },
      {
        id: "start-date",
        ref: startDateRef,
        name: "startDate",
        label: "Start Date",
        type: "date",
        value: event.startDate.split("T")[0],
        size: { xs: 12, md: "6" },
        disabled: true,
      },
      {
        id: "end-date",
        ref: endDateRef,
        name: "endDate",
        label: "End Date",
        type: "date",
        value: event.endDate.split("T")[0],
        size: { xs: 12, md: "6" },
        disabled: true,
      },
      {
        id: "start-time",
        ref: startTimeRef,
        name: "startTime",
        label: "Start Time",
        type: "time",
        value: event.startTime,
        size: { xs: 12, md: "6" },
        disabled: true,
      },
      {
        id: "end-time",
        ref: endTimeRef,
        name: "endTime",
        label: "End Time",
        type: "time",
        value: event.endTime,
        size: { xs: 12, md: "6" },
        disabled: true,
      },
      {
        id: "prevreg",
        ref: registrationRequiredRef,
        name: "registrationRequired",
        label: "Registration Required",
        type: "switch",
        value: event.registrationRequired,
        size: { xs: 12 },
        disabled: true,
      },
      {
        id: "public",
        ref: publicRef,
        name: "isPublic",
        label: "Public",
        type: "switch",
        value: event.isPublic,
        size: { xs: 12 },
        disabled: true,
      },
      {
        id: "cancelled",
        ref: cancelledRef,
        name: "isCancelled",
        label: "Cancelled",
        type: "switch",
        value: event.isCancelled,
        size: { xs: 12 },
        disabled: true,
      },
    ],
    actions: [
      {
        label: "Edit",
        type: "button",
        icon: "edit",
        onClick: () => {
          navigate(`/events/${event.code}/edit`);
        },
        variant: "primary",
      },
    ],
    maxWidth: "800px",
    layoutBreakpoint: "md",
    actionsBreakpoint: "sm",
  };

  return (
    <MasterPage breadcrumb={breadcrumb}>
      <div className="hstack justify-content-center">
        <div
          className="vstack align-items-center gap-3 p-3"
          style={{ maxWidth: "800px" }}
        >
          <h1>{`${event.name} - Management`}</h1>
          <Accordion
            className="w-100"
            sections={[
              { title: "Details", content: <Form {...detailsFormProps} /> },
              {
                title: "Attendance",
                content: (
                  <div className="row">
                    <div className="offset-md-1 col-12 col-md-10">
                      <div className="vstack gap-2">
                        <List
                          className="border rounded p-3"
                          items={attendees}
                          fields={attendeesFields}
                          search={true}
                          searchValue={search}
                          searchButton={true}
                          onSearch={async (searchString) => {
                            console.log(searchString);
                            let result = await fetch(
                              `/api/events/${event.code}/attendees?registered=true&page=${currentPage}&size=${pageSize}&search=${searchString}&count=true`
                            );
                            if (!result.ok) {
                              // TODO: Handle error
                              return;
                            }
                            let data = await result.json();
                            if (
                              data.attendees.length === 0 &&
                              data.totalCount > 0
                            ) {
                              const lastPage = Math.ceil(
                                data.totalCount / pageSize
                              );
                              result = await fetch(
                                `/api/events/${event.code}/attendees?registered=true&page=${lastPage}&size=${pageSize}&search=${searchString}&count=true`
                              );
                              if (!result.ok) {
                                // TODO: Handle error
                                return;
                              }
                              setCurrentPage(lastPage);
                              data = await result.json();
                            }
                            setSearch(searchString);
                            setAttendees(
                              data.attendees.map((attendee) => ({
                                id: attendee.code,
                                data: attendee,
                                selected: false,
                              }))
                            );
                            setTotalCount(data.totalCount);
                          }}
                          pagination={true}
                          page={currentPage}
                          size={pageSize}
                          totalCount={totalCount}
                          allowedSizes={[pageSize]}
                          onPaginationChange={async (page, size) => {
                            console.log(page, size);
                            let result = await fetch(
                              `/api/events/${event.code}/attendees?registered=true&page=${page}&size=${pageSize}&search=${search}`
                            );
                            if (!result.ok) {
                              // TODO: Handle error
                              return;
                            }
                            let data = await result.json();
                            setCurrentPage(page);
                            setAttendees(
                              data.attendees.map((attendee) => ({
                                id: attendee.code,
                                data: attendee,
                                selected: false,
                              }))
                            );
                          }}
                          itemLabel=""
                          itemLabelPlural=""
                          hideSummary={true}
                        />
                        <div className="row g-2 flex-sm-row-reverse">
                          <div className="col-12 col-sm-auto">
                            <Link
                              className="btn btn-primary w-100"
                              to={`/events/${event.code}/registration`}
                            >
                              Add or Remove
                            </Link>
                          </div>
                          <div className="col-12 col-sm-auto">
                            <Link
                              className="btn btn-primary w-100"
                              to={`/events/${event.code}/check-in`}
                            >
                              QR Check-In
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ),
              },
            ]}
          />
        </div>
      </div>
      <Toast ref={toastRef} />
    </MasterPage>
  );
};

export default View;

import React, { useState, useEffect } from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import AuthContext from './store/auth-context';
import routes from './routes';

const router = createBrowserRouter(routes);

const App = () => {
  const [authState, setAuthState] = useState({});

  useEffect(() => {
    const code = sessionStorage.getItem('code');
    if (code) {
      setAuthState({
        code: parseInt(code, 10),
        firstName: sessionStorage.getItem('firstName'),
        lastName: sessionStorage.getItem('lastName'),
        role: sessionStorage.getItem('role'),
        roleId: parseInt(sessionStorage.getItem('roleId'), 10),
        token: sessionStorage.getItem('token'),
      });
    }
  }, []);

  async function logIn(email, password) {
    const response = await fetch('/api/login', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email, password }),
    });
    if (!response.ok) {
      return false;
    }
    const { data } = await response.json();
    Object.entries(data).forEach(([key, value]) => {
      sessionStorage.setItem(key, value);
    });
    setAuthState(data);
    return true;
  }

  function logOut() {
    sessionStorage.removeItem('code');
    sessionStorage.removeItem('firstName');
    sessionStorage.removeItem('lastName');
    sessionStorage.removeItem('role');
    sessionStorage.removeItem('roleId');
    sessionStorage.removeItem('token');
    setAuthState({});
  }

  return (
    <AuthContext.Provider
      value={{
        ...authState,
        logIn,
        logOut,
      }}
    >
      <RouterProvider router={router} />
    </AuthContext.Provider>
  );
};

export default App;

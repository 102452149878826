import React from 'react';

const Switch = (props) => {
  const { type, isValid, ...innerProps } = props;
  return (
    <div
      className="hstack align-items-center"
      style={{ height: 'calc(2.25rem + 2px)' }}
    >
      <div className="form-check form-switch">
        <input
          className="form-check-input"
          type="checkbox"
          role="switch"
          {...innerProps}
        />
      </div>
    </div>
  );
};

export default Switch;

import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react';
import Input from '../../../../../UI/Input';
import InputPassword from '../../../../../UI/InputPassword';
import TextArea from '../../../../../UI/TextArea';
import Select from '../../../../../UI/Select';
import SelectPlus from '../../../../../UI/SelectPlus';
import Switch from '../../../../../UI/Switch';
import styles from './index.module.css';

const breakpoints = ['xs', 'sm', 'md', 'lg', 'xl', 'xxl'];

function breakpointToClass(breakpoint, size) {
  return breakpoint === 'xs' ? `col-${size}` : `col-${breakpoint}-${size}`;
}

function makeClass(size) {
  if (!size) {
    return 'col-12';
  }
  return breakpoints.reduce(
    (acc, breakpoint) =>
      size[breakpoint]
        ? `${acc} ${breakpointToClass(breakpoint, size[breakpoint])}`
        : acc,
    ''
  );
}

const FormItem = forwardRef((props, ref) => {
  const {
    type,
    id,
    label,
    required,
    labelContainerClass,
    inputContainerClass,
    size,
    value,
    onChange,
    onBlur,
    formId,
    validations,
    validationDisabled,
    formValidationDisabled,
    breakpoint,
    innerComponent,
    innerComponentProps,
    hiddenAlerts,
    help,
    ...innerProps
  } = props;

  const InnerComponent = innerComponent;

  const inputId = `${formId}-${id}`;

  const [innerValue, setInnerValue] = useState(value);
  const [wasValidated, setWasValidated] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const [alerts, setAlerts] = useState([]);

  useEffect(() => {
    setInnerValue(value);
  }, [value]);

  const className = useMemo(() => makeClass(size), [size]);

  const validate = useCallback(async () => {
    if (validationDisabled) {
      return true;
    }
    if (!validations) {
      setIsValid(true);
      setWasValidated(true);
      return true;
    }
    const validationValues = await Promise.all(
      validations.map((validation) => validation.validate(innerValue))
    );
    const currentAlerts = validations.filter(
      (validation, index) => !validationValues[index]
    );
    const isValid = currentAlerts.length === 0;
    setAlerts(currentAlerts);
    setIsValid(isValid);
    setWasValidated(true);
    return isValid;
  }, [validationDisabled, validations, innerValue]);

  useImperativeHandle(
    ref,
    () => ({
      getValue: () => innerValue,
      setValue: (newValue) => setInnerValue(newValue),
      validate: () => validate(innerValue),
      setIsValid: () => {
        setWasValidated(true);
        setIsValid(true);
        setAlerts([]);
      },
      reset: () => {
        setInnerValue(value);
        if (!formValidationDisabled || !validationDisabled) {
          setWasValidated(false);
          setIsValid(false);
          setAlerts([]);
        }
      },
    }),
    [innerValue, validate, value, formValidationDisabled, validationDisabled]
  );

  function changeHandler(event) {
    const newValue = event.target.value;
    setInnerValue(newValue);
    if (onChange) {
      onChange(event);
    }
  }

  function switchChangeHandler(event) {
    const newValue = event.target.checked;
    setInnerValue(newValue);
    if (onChange) {
      onChange(event);
    }
  }

  function blurHandler(event) {
    if (!formValidationDisabled || !validationDisabled) {
      validate();
    }
    if (onBlur) {
      onBlur(event);
    }
  }

  return (
    <div className="row g-0">
      <div className={labelContainerClass}>
        <label
          className={`${breakpoint ? `d-${breakpoint}-none` : ''} mb-1`}
          htmlFor={inputId}
        >
          {label}
          {required && (
            <span className="text-danger ps-1" aria-hidden="true">
              *
            </span>
          )}
        </label>
        <div
          className={`hstack justify-content-end me-3 d-none ${
            breakpoint ? `d-${breakpoint}-flex` : ''
          } text-nowrap`}
          style={{ height: 'calc(2.25rem + 2px)' }}
        >
          <label htmlFor={inputId}>
            {required && (
              <span className="text-danger pe-1" aria-hidden="true">
                *
              </span>
            )}
            {label}
          </label>
        </div>
      </div>
      <div className={inputContainerClass}>
        <div className="vstack gap-1">
          <div className="row">
            <div className={className}>
              {type === 'select-plus' ? (
                <SelectPlus
                  id={inputId}
                  value={innerValue}
                  onChange={changeHandler}
                  onBlur={blurHandler}
                  wasValidated={wasValidated}
                  isValid={isValid}
                  {...innerProps}
                />
              ) : type === 'select' ? (
                <Select
                  id={inputId}
                  value={innerValue}
                  onChange={changeHandler}
                  onBlur={blurHandler}
                  wasValidated={wasValidated}
                  isValid={isValid}
                  {...innerProps}
                />
              ) : type === 'switch' ? (
                <Switch
                  id={inputId}
                  checked={innerValue}
                  onChange={switchChangeHandler}
                  {...innerProps}
                />
              ) : type === 'area' ? (
                <TextArea
                  id={inputId}
                  value={innerValue}
                  onChange={changeHandler}
                  onBlur={blurHandler}
                  wasValidated={wasValidated}
                  isValid={isValid}
                  {...innerProps}
                />
              ) : type === 'password' ? (
                <InputPassword
                  id={inputId}
                  value={innerValue}
                  onChange={changeHandler}
                  onBlur={blurHandler}
                  wasValidated={wasValidated}
                  isValid={isValid}
                  {...innerProps}
                />
              ) : (
                <Input
                  id={inputId}
                  value={innerValue}
                  onChange={changeHandler}
                  onBlur={blurHandler}
                  wasValidated={wasValidated}
                  isValid={isValid}
                  type={type}
                  {...innerProps}
                />
              )}
            </div>
          </div>
          {help && alerts.length === 0 && <span>{help}</span>}
          <div className={hiddenAlerts ? 'visually-hidden' : ''} role="alert">
            <ul className={`m-0 p-0 text-danger ${styles['alert-list']}`}>
              {alerts.map((alert, index) => (
                <li key={index}>{alert.message}</li>
              ))}
            </ul>
          </div>
          {innerComponent && (
            <InnerComponent
              value={value}
              setValue={setInnerValue}
              {...innerComponentProps}
            />
          )}
        </div>
      </div>
    </div>
  );
});

export default FormItem;

import React, { Fragment, useEffect, useMemo, useRef } from 'react';
import ItemCard from './components/ItemCard';

const ItemsContainer = (props) => {
  const selectedCount = useMemo(
    () =>
      props.selection
        ? props.items.reduce(
            (count, item) => (item.selected ? count + 1 : count),
            0
          )
        : 0,
    [props.items, props.selection]
  );

  const columnCount =
    props.fields.length + (props.actions ? 1 : 0) + (props.selection ? 1 : 0);

  const allItemsSelected = selectedCount === props.items.length;

  const indeterminateSelection = selectedCount > 0 && !allItemsSelected;

  const selectionRef1 = useRef(null);
  // const selectionRef2 = useRef(null);

  useEffect(() => {
    if (!props.selection) {
      return;
    }
    selectionRef1.current.indeterminate = indeterminateSelection;
    // selectionRef2.current.indeterminate = indeterminateSelection;
  });

  function allItemsSelectionChangeHandler(event) {
    if (props.onItemSelectionChange) {
      props.onAllItemsSelectionChange(event.target.checked);
    }
  }

  function itemSelectionChangeHandler(id) {
    return (event) => {
      if (props.onItemSelectionChange) {
        props.onItemSelectionChange(id, event.target.checked);
      }
    };
  }

  function fieldClickHandler(name) {
    return () => {
      if (!props.sort) {
        return;
      }
      if (props.sortField === name) {
        props.onSortChange(
          name,
          props.sortDirection === 'asc' ? 'desc' : 'asc'
        );
      } else {
        props.onSortChange(name, 'asc');
      }
    };
  }

  return (
    <Fragment>
      {/* <div className="d-none d-md-flex flex-column gap-1">
        {props.items.map((item) => (
          <div key={item.id} className="bg-info">
            {item.data.name}
          </div>
        ))}
      </div>
      <div className="row row-cols-1 row-cols-sm-2 g-1 d-md-none">
        {props.items.map((item) => (
          <div key={item.id} className="col">
            <ItemCard
              title={item.title}
              data={item.data}
              selected={item.selected}
              fields={props.fields}
            />
          </div>
        ))}
      </div> */}
      <div
        className="table-responsive border rounded bg-white"
        style={{
          height: props.height || 'auto',
          minHeight: props.minHeight || 'auto',
        }}
      >
        <table className="table align-middle table-borderless mb-0">
          <thead className="bg-white sticky-top" style={{ zIndex: 10 }}>
            <tr
              className="bg-body-secondary bg-opacity-50 align-bottom"
              style={{ height: '40px' }}
            >
              {props.selection && (
                <th className="px-3" style={{ width: '1px' }} scope="col">
                  <input
                    ref={selectionRef1}
                    className="form-check-input"
                    type="checkbox"
                    checked={allItemsSelected}
                    onChange={allItemsSelectionChangeHandler}
                    aria-label="Select all"
                  />
                </th>
              )}
              {props.fields.map((field, index) => (
                <th
                  key={index}
                  className="px-3 py-2"
                  style={{
                    cursor: 'default',
                    width: field.fit ? '1px' : 'auto',
                    textAlign: field.align || 'left',
                  }}
                  scope="col"
                >
                  <span
                    className="user-select-none text-nowrap"
                    style={{
                      cursor: props.sort ? 'pointer' : 'default',
                    }}
                    onClick={fieldClickHandler(field.name)}
                  >
                    {field.label}
                    {props.sort && (
                      <span
                        className={`material-symbols-rounded ms-1 ${
                          props.sortField !== field.name
                            ? 'text-body-tertiary'
                            : ''
                        }`}
                        style={{
                          fontSize: '1em',
                          verticalAlign: '-15%',
                          fontVariationSettings: `'FILL' 1, 'wght' 700, 'GRAD' 0, 'opsz' 20`,
                        }}
                        aria-hidden="true"
                      >
                        {props.sortField === field.name &&
                        props.sortDirection === 'desc'
                          ? 'south'
                          : 'north'}
                      </span>
                    )}
                  </span>
                </th>
              ))}
              {Array.isArray(props.actions) && props.actions.length > 0 && (
                <th className="px-3" style={{ width: 1 }}>
                  <span className="visually-hidden">Actions</span>
                </th>
              )}
            </tr>
          </thead>
          <tbody>
            {props.items.map((item, index) => (
              <tr
                key={item.id}
                className={`border-top ${
                  props.items.length - 1 === index ? 'border-bottom' : ''
                } ${
                  props.selection && item.selected
                    ? 'border-bottom border-primary-subtle bg-primary bg-opacity-10'
                    : ''
                }`}
              >
                {props.selection && (
                  <td className="px-3">
                    <input
                      className="form-check-input pt-2"
                      type="checkbox"
                      checked={item.selected}
                      onChange={itemSelectionChangeHandler(item.id)}
                      aria-label={`Select ${item.id}`}
                    />
                  </td>
                )}
                {props.fields.map((field, index) => (
                  <td
                    key={index}
                    className="bg-opacity-75 p-3"
                    style={{ textAlign: field.align || 'left' }}
                  >
                    {field.getValue(item.data)}
                  </td>
                ))}
                {Array.isArray(props.actions) && props.actions.length > 0 && (
                  <td className="px-3">
                    <div className="dropdown">
                      <button
                        className="btn btn-primary px-1 py-0 bg-transparent border-0 text-body"
                        type="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        <span
                          className="material-symbols-rounded user-select-none"
                          style={{
                            fontSize: '1.2em',
                            verticalAlign: '-20%',
                          }}
                          aria-hidden="true"
                        >
                          more_vert
                        </span>
                      </button>
                      <ul
                        className="dropdown-menu"
                        // style={{ minWidth: '0' }}
                      >
                        {props.actions
                          .filter(
                            (action) =>
                              !action.condition || action.condition(item.data)
                          )
                          .map((action, index) => (
                            <li key={index}>
                              <button
                                className="dropdown-item w-100 px-3 py-1 text-nowrap"
                                type="button"
                                onClick={() => action.onClick(item.id)}
                              >
                                {action.icon && (
                                  <span
                                    className="material-symbols-rounded user-select-none me-2"
                                    style={{
                                      fontSize: '1em',
                                      verticalAlign: '-15%',
                                      fontVariationSettings: `'FILL' 1, 'wght' 500, 'GRAD' 0, 'opsz' 20`,
                                    }}
                                    aria-hidden="true"
                                  >
                                    {action.icon}
                                  </span>
                                )}
                                {action.label}
                              </button>
                            </li>
                          ))}
                      </ul>
                    </div>
                  </td>
                )}
              </tr>
            ))}
            {props.items.length === 0 && (
              <tr>
                <td className="p-5 text-center" colSpan={columnCount}>
                  No {props.itemLabelPlural || 'items'} to show!
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <div className="d-none"></div>
    </Fragment>
  );
};

export default ItemsContainer;

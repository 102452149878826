import Main from '../pages/Services/Main';
import Create from '../pages/Services/Create';
import View from '../pages/Services/View';
import Edit from '../pages/Services/Edit';

const routes = [
  {
    path: '/services',
    element: <Main />,
    loader: ({ params, request }) => {
      return null;
    },
  },
  {
    path: '/services/create',
    element: <Create />,
    loader: ({ params, request }) => {
      return null;
    },
  },
  {
    path: '/services/:code',
    element: <View />,
    loader: ({ params, request }) => {
      return null;
    },
  },
  {
    path: '/services/:code/edit',
    element: <Edit />,
    loader: ({ params, request }) => {
      return null;
    },
  },
];

export default routes;

import React, { Fragment, useRef } from 'react';
import styles from './index.module.css';

const Select = (props) => {
  const {
    isValid,
    wasValidated,
    width,
    disabled,
    value,
    options,
    placeholder,
    ...innerProps
  } = props;

  const selectedOption = options.find((option) => option.value === value);
  const label = selectedOption
    ? selectedOption.label
    : placeholder
    ? placeholder
    : '';

  return (
    <div
      className={`border rounded position-relative overflow-hidden ${
        styles.container
      } ${wasValidated ? (isValid ? styles.valid : styles.invalid) : ''}`}
      style={{
        width: width ? width : '100%',
        minWidth: width ? width : '0',
      }}
    >
      <select
        className={`w-100 h-100 ${styles.select}`}
        value={value}
        disabled={disabled}
        {...innerProps}
      >
        {!selectedOption && (
          <option value={''} disabled>
            -- Select an option --
          </option>
        )}
        {options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
      <div
        className={`position-absolute left-0 top-0 w-100 h-100 bg-${
          disabled ? 'body-tertiary' : 'white'
        } hstack gap-1 pe-none ${styles.cover}`}
      >
        <div className="flex-grow-1 overflow-hidden">
          <span
            className="text-nowrap"
            style={{
              color: selectedOption ? 'var(--bs-text-dark)' : '#757575 ',
            }}
          >
            {label}
          </span>
        </div>
        {wasValidated && (
          <span
            className={`material-symbols-rounded text-${
              isValid ? 'success' : 'danger'
            } user-select-none ${styles.icon}`}
            aria-hidden="true"
          >
            {isValid ? 'check_circle' : 'error'}
          </span>
        )}
        <span
          className={`material-symbols-rounded align-self-end ${styles.chevron}`}
          aria-hidden="true"
        >
          expand_more
        </span>
      </div>
    </div>
  );
};

export default Select;

import Main from "../pages/Events/Main";
import Create from "../pages/Events/Create";
import View from "../pages/Events/View";
import Management from "../pages/Events/Management";
import Edit from "../pages/Events/Edit";
import Registration from "../pages/Events/Registration";
import CheckIn from "../pages/Events/CheckIn";

import { utcToLocal } from "../utils/utc";

const routes = [
  {
    path: "/events",
    element: <Main />,
    loader: ({ params, request }) => {
      const url = new URL(request.url);
      const searchParamsString = url.searchParams.toString();
      const apiUrl =
        searchParamsString.length === 0
          ? "/api/events"
          : `/api/events?${searchParamsString}`;
      return Promise.all([
        fetch(apiUrl)
          .then((result) => result.json())
          .then((data) => {
            const { events: base } = data;
            const events = [];
            base.forEach((event) => {
              const [startDate, startTime] = utcToLocal(
                event.startDate,
                event.startTime
              );
              const [endDate, endTime] = utcToLocal(
                event.endDate,
                event.endTime
              );
              events.push({ ...event, startDate, startTime, endDate, endTime });
            });
            return { ...data, events };
          }),
        fetch("/api/venues").then((result) => result.json()),
      ]);
    },
  },
  {
    path: "/events/new",
    element: <Create />,
    loader: ({ params, request }) => {
      return Promise.all([
        fetch("/api/event-categories").then((result) => result.json()),
        fetch("/api/programs").then((result) => result.json()),
        fetch("/api/venues").then((result) => result.json()),
        fetch("/api/program-categories").then((result) => result.json()),
        fetch("/api/postal-codes").then((result) => result.json()),
      ]);
    },
  },
  {
    path: "/events/:code",
    element: <View />,
    loader: ({ params, request }) => {
      return Promise.all([
        fetch(`/api/events/${params.code}`)
          .then((result) => result.json())
          .then((data) => {
            const [startDate, startTime] = utcToLocal(
              data.event.startDate,
              data.event.startTime
            );
            const [endDate, endTime] = utcToLocal(
              data.event.endDate,
              data.event.endTime
            );
            return {
              event: { ...data.event, startDate, startTime, endDate, endTime },
            };
          }),
        fetch(
          `/api/events/${params.code}/attendees?registered=true&count=true&page=1&size=10`
        ).then((result) => result.json()),
      ]);
    },
  },
  {
    path: "/events/:code/registration",
    element: <Registration />,
    loader: ({ params, request }) => {
      return Promise.all([
        fetch(`/api/events/${params.code}`).then((result) => result.json()),
        fetch(
          `/api/events/${params.code}/attendees?count=true&page=1&size=10`
        ).then((result) => result.json()),
      ]);
    },
  },
  {
    path: "/events/:code/check-in",
    element: <CheckIn />,
    loader: ({ params, request }) => {
      return Promise.all([
        fetch(`/api/events/${params.code}`).then((result) => result.json()),
      ]);
    },
  },
  {
    path: "/events/:code/edit",
    element: <Edit />,
    loader: ({ params, request }) => {
      return Promise.all([
        fetch(`/api/events/${params.code}`)
          .then((result) => result.json())
          .then((data) => {
            const [startDate, startTime] = utcToLocal(
              data.event.startDate,
              data.event.startTime
            );
            const [endDate, endTime] = utcToLocal(
              data.event.endDate,
              data.event.endTime
            );
            return {
              event: { ...data.event, startDate, startTime, endDate, endTime },
            };
          }),
        // .then((data) => {
        //   console.log(data.event);
        //   return data;
        // }),
        fetch("/api/event-categories").then((result) => result.json()),
        fetch("/api/programs").then((result) => result.json()),
        fetch("/api/venues").then((result) => result.json()),
        fetch("/api/program-categories").then((result) => result.json()),
        fetch("/api/postal-codes").then((result) => result.json()),
      ]);
    },
  },
];

export default routes;

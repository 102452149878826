import React, { useEffect, useMemo, useRef, useState } from "react";
import { useLoaderData, useNavigate } from "react-router-dom";
import MasterPage from "../../../components/MasterPage";
import Form from "../../../components/Form";
import ModalForm from "../../../components/ModalForm";
import Toast from "../../../components/Toast";

import { localToUtc } from "../../../utils/utc";

const breadcrumb = [
  { label: "Events", path: "/events?sort=start-date&direction=desc" },
  { label: "New" },
];

const compareFn = (a, b) =>
  a.label === b.label ? 0 : a.label < b.label ? -1 : 1;

const Create = (props) => {
  const navigate = useNavigate();

  const [
    { eventCategories: eventCategoriesBase },
    { programs: programsBase },
    { venues: venuesBase },
    { programCategories },
    { postalCodes: postalCodesBase },
  ] = useLoaderData();

  const [allPostalCodes, allCities, allProvinces, allCountries] =
    useMemo(() => {
      const citiesDict = {};
      const provincesDict = {};
      const countriesDict = {};
      const postalCodes = [];
      const cities = [];
      const provinces = [];
      const countries = [];
      postalCodesBase.forEach((postalCode) => {
        const {
          id,
          code,
          city,
          cityId,
          province,
          provinceId,
          country,
          countryId,
        } = postalCode;
        postalCodes.push({
          value: `${id}`,
          label: code,
          cityId: `${cityId}`,
          provinceId: `${provinceId}`,
          countryId: `${countryId}`,
        });

        if (citiesDict[city]) {
          return;
        }
        citiesDict[city] = true;
        cities.push({
          value: `${cityId}`,
          label: city,
          provinceId: `${provinceId}`,
          countryId: `${countryId}`,
        });
        if (provincesDict[province]) {
          return;
        }
        provincesDict[province] = true;
        provinces.push({
          value: `${provinceId}`,
          label: province,
          countryId: `${countryId}`,
        });
        if (countriesDict[country]) {
          return;
        }
        countriesDict[country] = true;
        countries.push({ value: `${countryId}`, label: country });
      });
      return [
        postalCodes.sort(compareFn),
        cities.sort(compareFn),
        provinces.sort(compareFn),
        countries.sort(compareFn),
      ];
    }, [postalCodesBase]);

  const [eventCategories, setEventCategories] = useState(
    eventCategoriesBase
      .map((eventCategory) => ({
        value: `${eventCategory.id}`,
        label: eventCategory.name,
      }))
      .sort(compareFn)
  );
  const [programs, setPrograms] = useState(
    programsBase
      .map((program) => ({
        value: `${program.id}`,
        label: program.name,
      }))
      .sort(compareFn)
  );
  const [venues, setVenues] = useState(
    venuesBase
      .map((venue) => ({
        value: `${venue.id}`,
        label: venue.name,
      }))
      .sort(compareFn)
  );
  const [postalCodes, setPostalCodes] = useState(allPostalCodes);
  const [cities, setCities] = useState(allCities);
  const [provinces, setProvinces] = useState([]);
  // const [countries, setCountries] = useState(allCountries);
  const [city, setCity] = useState("");
  const [province, setProvince] = useState("");
  const [country, setCountry] = useState("");

  useEffect(() => {
    setPostalCodes(city ? allPostalCodes.filter((p) => p.cityId === city) : []);
  }, [allPostalCodes, city]);

  useEffect(() => {
    setCities(
      province ? allCities.filter((c) => c.provinceId === province) : []
    );
  }, [allCities, province]);

  useEffect(() => {
    setProvinces(
      country ? allProvinces.filter((p) => p.countryId === country) : []
    );
  }, [allProvinces, country]);

  const toastRef = useRef(null);

  const categoryModalRef = useRef(null);
  const categoryNameRef = useRef(null);
  const categoryDescriptionRef = useRef(null);
  const categoryModalProps = {
    id: "category-modal",
    formId: "category-form",
    title: "New Event Category",
    ref: categoryModalRef,
    labelContainerSize: { md: 3 },
    items: [
      {
        id: "name",
        ref: categoryNameRef,
        name: "name",
        label: "Name",
        type: "text",
        value: "",
        validations: [
          //TODO - add validation for unique name
          {
            validate: (value) => value.length > 0,
            message: "Name is required.",
          },
        ],
        size: { xs: 12 },
        required: true,
      },
      {
        id: "description",
        ref: categoryDescriptionRef,
        name: "description",
        label: "Description",
        type: "area",
        value: "",
        size: { xs: 12 },
      },
    ],
    layoutBreakpoint: "md",
    actionsBreakpoint: "sm",
    actions: [
      {
        label: "Save",
        type: "submit",
        variant: "primary",
        form: "category-form",
      },
      {
        id: "category-modal-cancel",
        label: "Cancel",
        type: "button",
        onClick: () => {
          categoryModalRef.current.reset();
        },
        variant: "secondary",
        "data-bs-dismiss": "modal",
      },
    ],
    onClose: () => {
      categoryModalRef.current.reset();
    },
    onSubmit: async (event) => {
      event.preventDefault();
      const isValid = await categoryModalRef.current.validate();
      if (!isValid) {
        toastRef.current.showToast(
          "Event category create failed. Please check for errors.",
          "danger"
        );
        return;
      }
      const category = categoryModalRef.current.getData();
      const result = await fetch("/api/event-categories", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(category),
      });
      const data = await result.json();
      if (result.ok) {
        categoryModalRef.current.reset();
        const cancel = document.getElementById("category-modal-cancel");
        cancel.click();
        toastRef.current.show(
          "Event category created successfully.",
          "success"
        );
        setEventCategories((previous) =>
          [...previous, { value: `${data.id}`, label: category.name }].sort(
            compareFn
          )
        );
        categoryRef.current.setValue(`${data.id}`);
        categoryRef.current.setIsValid();
        return;
      }
      if (result.status === 500) {
        toastRef.current.show(
          "Event category create failed. Please try again.",
          "danger"
        );
        return;
      }
      toastRef.current.show(
        `Event category create failed. ${data.message}.`,
        "danger"
      );
    },
  };

  const programModalRef = useRef(null);
  const programNameRef = useRef(null);
  const programDescriptionRef = useRef(null);
  const programCategoryRef = useRef(null);
  const programModalProps = {
    id: "program-modal",
    formId: "program-form",
    title: "New Program",
    ref: programModalRef,
    labelContainerSize: { md: 3 },
    items: [
      {
        id: "category",
        ref: programCategoryRef,
        name: "categoryId",
        label: "Category",
        type: "select",
        value: "",
        options: programCategories.map((category) => ({
          value: `${category.id}`,
          label: category.name,
        })),
        validations: [
          {
            validate: (value) => value.length > 0,
            message: "Category is required.",
          },
        ],
        size: { xs: 12 },
        required: true,
      },
      {
        id: "name",
        ref: programNameRef,
        name: "name",
        label: "Name",
        type: "text",
        value: "",
        validations: [
          //TODO - add validation for unique name
          {
            validate: (value) => value.length > 0,
            message: "Name is required.",
          },
        ],
        size: { xs: 12 },
        required: true,
      },
      {
        id: "description",
        ref: programDescriptionRef,
        name: "description",
        label: "Description",
        type: "area",
        value: "",
        size: { xs: 12 },
      },
    ],
    layoutBreakpoint: "md",
    actionsBreakpoint: "sm",
    actions: [
      {
        label: "Save",
        type: "submit",
        variant: "primary",
        form: "program-form",
      },
      {
        id: "program-modal-cancel",
        label: "Cancel",
        type: "button",
        onClick: () => {
          programModalRef.current.reset();
        },
        variant: "secondary",
        "data-bs-dismiss": "modal",
      },
    ],
    onClose: () => {
      programModalRef.current.reset();
    },
    onSubmit: async (event) => {
      event.preventDefault();
      const isValid = await programModalRef.current.validate();
      if (!isValid) {
        toastRef.current.showToast(
          "Program create failed. Please check for errors.",
          "danger"
        );
        return;
      }
      const program = programModalRef.current.getData();
      const result = await fetch("/api/programs", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(program),
      });
      const data = await result.json();
      if (result.ok) {
        toastRef.current.show("Program created successfully.", "success");
        programModalRef.current.reset();
        const cancel = document.getElementById("program-modal-cancel");
        cancel.click();
        setPrograms((previous) =>
          [...previous, { value: `${data.id}`, label: program.name }].sort(
            compareFn
          )
        );
        programRef.current.setValue(`${data.id}`);
        programRef.current.setIsValid();
        return;
      }
      if (result.status === 500) {
        toastRef.current.show(
          "Program create failed. Please try again.",
          "danger"
        );
        return;
      }
      toastRef.current.show(
        `Program create failed. ${data.message}.`,
        "danger"
      );
    },
  };

  const venueModalRef = useRef(null);
  const venueNameRef = useRef(null);
  const venuePhonesRef = useRef(null);
  const venueWebsiteRef = useRef(null);
  const venueAddressLine1Ref = useRef(null);
  const venueAddressLine2Ref = useRef(null);
  const venuePostalCodeRef = useRef(null);
  const venueCityRef = useRef(null);
  const venueProvinceRef = useRef(null);
  const venueCountryRef = useRef(null);
  const venueModalProps = {
    id: "venue-modal",
    title: "New Venue",
    formId: "venue-form",
    ref: venueModalRef,
    labelContainerSize: { md: 3 },
    items: [
      {
        id: "name",
        ref: venueNameRef,
        name: "name",
        label: "Name",
        type: "text",
        value: "",
        validations: [
          {
            validate: (value) => value.length > 0,
            message: "Name is required.",
          },
        ],
        size: { xs: 12 },
        required: true,
      },
      {
        id: "phones",
        ref: venuePhonesRef,
        name: "phones",
        label: "Phones",
        type: "text",
        value: "",
        size: { xs: 12 },
      },
      {
        id: "website",
        ref: venueWebsiteRef,
        name: "website",
        label: "Website",
        type: "text",
        value: "",
        size: { xs: 12 },
      },
      {
        id: "address-line-1",
        ref: venueAddressLine1Ref,
        name: "addressLine1",
        label: "Addr. Line 1",
        type: "text",
        value: "",
        size: { xs: 12 },
        required: true,
        validations: [
          {
            validate: (value) => value.length > 0,
            message: "Address line 1 is required.",
          },
        ],
      },
      {
        id: "address-line-2",
        ref: venueAddressLine2Ref,
        name: "addressLine2",
        label: "Addr. Line 2",
        type: "text",
        value: "",
        size: { xs: 12 },
      },
      {
        id: "country",
        ref: venueCountryRef,
        name: "country",
        label: "Country",
        type: "select",
        options: allCountries,
        value: "",
        size: { xs: 12 },
        required: true,
        validations: [
          {
            validate: (value) => value.length > 0,
            message: "Country is required.",
          },
        ],
        onChange: (event) => {
          setCountry(event.target.value);
          setProvince("");
          setCity("");
          venueProvinceRef.current.setValue("");
          venueCityRef.current.setValue("");
          venuePostalCodeRef.current.setValue("");
          venueProvinceRef.current.reset();
          venueCityRef.current.reset();
          venuePostalCodeRef.current.reset();
        },
      },
      {
        id: "province",
        ref: venueProvinceRef,
        name: "province",
        label: "Province",
        type: "select",
        help: "Select a country first.",
        options: provinces,
        value: "",
        size: { xs: 12 },
        required: true,
        validations: [
          {
            validate: (value) => value.length > 0,
            // message: 'Province is required.',
          },
        ],
        disabled: !country,
        onChange: (event) => {
          setProvince(event.target.value);
          setCity("");
          venueCityRef.current.setValue("");
          venuePostalCodeRef.current.setValue("");
          venueCityRef.current.reset();
          venuePostalCodeRef.current.reset();
        },
      },
      {
        id: "city",
        ref: venueCityRef,
        name: "city",
        label: "City",
        type: "select",
        help: "Select a province first.",
        options: cities,
        value: "",
        size: { xs: 12 },
        required: true,
        validations: [
          {
            validate: (value) => value.length > 0,
            message: "City is required.",
          },
        ],
        disabled: !province,
        onChange: (event) => {
          setCity(event.target.value);
          venuePostalCodeRef.current.setValue("");
          venuePostalCodeRef.current.reset();
        },
      },
      {
        id: "postal-code",
        ref: venuePostalCodeRef,
        name: "postalCodeId",
        label: "Postal Code",
        type: "select",
        help: "Select a city first.",
        options: postalCodes,
        value: "",
        size: { xs: 12 },
        required: true,
        validations: [
          {
            validate: (value) => value.length > 0,
            message: "Postal code is required.",
          },
        ],
        disabled: !city,
      },
    ],
    layoutBreakpoint: "md",
    actionsBreakpoint: "sm",
    actions: [
      {
        label: "Save",
        type: "submit",
        variant: "primary",
        form: "venue-form",
      },
      {
        id: "venue-modal-cancel",
        label: "Cancel",
        type: "button",
        onClick: () => {
          venueModalRef.current.reset();
          setCountry("");
          setProvince("");
          setCity("");
        },
        variant: "secondary",
        "data-bs-dismiss": "modal",
      },
    ],
    onClose: () => {
      venueModalRef.current.reset();
      setCountry("");
      setProvince("");
      setCity("");
    },
    onSubmit: async (event) => {
      event.preventDefault();
      const isValid = await venueModalRef.current.validate();
      if (!isValid) {
        toastRef.current.show(
          "Venue create failed. Please check for errors.",
          "danger"
        );
        return;
      }
      const venue = venueModalRef.current.getData();
      const result = await fetch("/api/venues", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(venue),
      });
      const data = await result.json();
      if (result.ok) {
        toastRef.current.show("Venue created successfully.", "success");
        venueModalRef.current.reset();
        const cancel = document.getElementById("venue-modal-cancel");
        cancel.click();
        setVenues((previous) =>
          [...previous, { value: `${data.id}`, label: venue.name }].sort(
            compareFn
          )
        );
        venueRef.current.setValue(`${data.id}`);
        venueRef.current.setIsValid();
        return;
      }
      if (result.status === 500) {
        toastRef.current.show(
          "Venue create failed. Please try again.",
          "danger"
        );
        return;
      }
      toastRef.current.show(`Venue create failed. ${data.message}.`, "danger");
    },
  };

  const formRef = useRef(null);
  const nameRef = useRef(null);
  const descriptionRef = useRef(null);
  const startDateRef = useRef(null);
  const endDateRef = useRef(null);
  const startTimeRef = useRef(null);
  const endTimeRef = useRef(null);
  const registrationRequiredRef = useRef(null);
  const publicRef = useRef(null);
  const venueRef = useRef(null);
  const programRef = useRef(null);
  const categoryRef = useRef(null);

  const formProps = {
    id: "event-form",
    className: "border rounded px-3 py-3 py-md-5",
    ref: formRef,
    labelContainerSize: { md: 3 },
    items: [
      {
        id: "program",
        ref: programRef,
        name: "programId",
        label: "Program",
        type: "select-plus",
        options: programs,
        value: "",
        required: true,
        validations: [
          {
            validate: (value) => value.length > 0,
            message: "Program is required.",
          },
        ],
        size: { xs: 12, md: "8" },
        plusProps: {
          "data-bs-toggle": "modal",
          "data-bs-target": "#program-modal",
        },
      },
      {
        id: "category",
        ref: categoryRef,
        name: "categoryId",
        label: "Category",
        type: "select-plus",
        options: eventCategories,
        value: "",
        required: true,
        validations: [
          {
            validate: (value) => value.length > 0,
            message: "Category is required.",
          },
        ],
        size: { xs: 12, md: "8" },
        plusProps: {
          "data-bs-toggle": "modal",
          "data-bs-target": "#category-modal",
        },
      },
      {
        id: "name",
        ref: nameRef,
        name: "name",
        label: "Name",
        type: "text",
        value: "",
        size: { xs: 12 },
        required: true,
        validations: [
          {
            validate: (value) => value.length > 0,
            message: "Name is required.",
          },
        ],
      },
      {
        id: "description",
        ref: descriptionRef,
        name: "description",
        label: "Description",
        type: "area",
        value: "",
        size: { xs: 12 },
      },
      {
        id: "venue",
        ref: venueRef,
        name: "venueId",
        label: "Venue",
        type: "select-plus",
        options: venues,
        value: "",
        required: true,
        validations: [
          {
            validate: (value) => value.length > 0,
            message: "Venue is required.",
          },
        ],
        size: { xs: 12, md: "8" },
        plusProps: {
          "data-bs-toggle": "modal",
          "data-bs-target": "#venue-modal",
        },
      },
      {
        id: "start-date",
        ref: startDateRef,
        name: "startDate",
        label: "Start Date",
        type: "date",
        value: "",
        size: { xs: 12, md: "6" },
        required: true,
        validations: [
          {
            validate: (value) => value.length > 0,
            message: "Start date is required.",
          },
        ],
      },
      {
        id: "end-date",
        ref: endDateRef,
        name: "endDate",
        label: "End Date",
        type: "date",
        value: "",
        size: { xs: 12, md: "6" },
        required: true,
        validations: [
          {
            validate: (value) => value.length > 0,
            message: "End date is required.",
          },
        ],
      },
      {
        id: "start-time",
        ref: startTimeRef,
        name: "startTime",
        label: "Start Time",
        type: "time",
        value: "",
        size: { xs: 12, md: "6" },
        required: true,
        validations: [
          {
            validate: (value) => value.length > 0,
            message: "Start time is required.",
          },
        ],
      },
      {
        id: "end-time",
        ref: endTimeRef,
        name: "endTime",
        label: "End Time",
        type: "time",
        value: "",
        size: { xs: 12, md: "6" },
        required: true,
        validations: [
          {
            validate: (value) => value.length > 0,
            message: "End time is required.",
          },
        ],
      },
      {
        id: "prevreg",
        ref: registrationRequiredRef,
        name: "registrationRequired",
        label: "Registration Required",
        type: "switch",
        value: false,
        size: { xs: 12 },
      },
      {
        id: "public",
        ref: publicRef,
        name: "isPublic",
        label: "Public",
        type: "switch",
        value: false,
        size: { xs: 12 },
      },
    ],
    actions: [
      {
        label: "Save",
        type: "submit",
        variant: "primary",
      },
      {
        label: "Cancel",
        type: "button",
        variant: "secondary",
        onClick: () => {
          navigate("/events");
        },
      },
    ],
    maxWidth: "800px",
    layoutBreakpoint: "md",
    actionsBreakpoint: "sm",
    onSubmit: async (event) => {
      event.preventDefault();
      const isValid = await formRef.current.validate();
      if (!isValid) {
        toastRef.current.show(
          "Event create failed! Please check for errors.",
          "danger"
        );
        return;
      }
      const data = formRef.current.getData();

      const { startDate, endDate, startTime, endTime } = data;

      const [utcStartDate, utcStartTime] = localToUtc(startDate, startTime);
      const [utcEndDate, utcEndTime] = localToUtc(endDate, endTime);

      data.startDate = utcStartDate;
      data.endDate = utcEndDate;
      data.startTime = utcStartTime;
      data.endTime = utcEndTime;

      const result = await fetch("/api/events", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });
      if (result.ok) {
        formRef.current.reset();
        toastRef.current.show("Event created successfully!", "success");
        return;
      }
      toastRef.current.show("Event create failed! Please try again.", "danger");
    },
  };

  return (
    <MasterPage breadcrumb={breadcrumb}>
      <div className="vstack gap-3 m-3" style={{}}>
        <div className="hstack justify-content-md-center align-items-center position-relative">
          <h1>New Event</h1>
        </div>
        <div className="hstack justify-content-center">
          <Form {...formProps} />
        </div>
      </div>
      <ModalForm {...venueModalProps} />
      <ModalForm {...programModalProps} />
      <ModalForm {...categoryModalProps} />
      <Toast ref={toastRef} />
    </MasterPage>
  );
};

export default Create;

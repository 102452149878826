import React, { useEffect, useRef, useState } from 'react';
import styles from './index.module.css';

const SearchBar = (props) => {
  const [value, setValue] = useState(props.value);

  const inputRef = useRef(null);

  useEffect(() => {
    setValue(props.value);
  }, [props.value]);

  function inputChangeHandler(event) {
    const currentValue = event.target.value;
    setValue(currentValue);
    if (props.onSearchChange) {
      props.onSearchChange(currentValue);
    }
  }

  function inputKeyDownHandler(event) {
    if (event.key === 'Enter') {
      event.preventDefault();
      event.stopPropagation();
      if (props.onSearch) {
        props.onSearch(value);
      }
    }
  }

  function resetButtonClickHandler(event) {
    event.preventDefault();
    event.stopPropagation();
    setValue('');
    if (props.onSearch) {
      props.onSearch('');
    }
  }

  function searchButtonClickHandler(event) {
    event.preventDefault();
    event.stopPropagation();
    if (props.onSearch) {
      props.onSearch(value);
    }
  }

  return (
    <div
      className={
        'row g-1 align-items-center border rounded bg-white overflow-hidden ' +
        styles['search-bar']
      }
    >
      <div className="col-auto">
        <span
          className="material-symbols-rounded ms-2 user-select-none"
          aria-hidden="true"
        >
          search
        </span>
      </div>
      <div className="col m-0">
        <div className="position-relative">
          <input
            ref={inputRef}
            className={`w-100 m-0 ${styles['search-input']}`}
            type="text"
            style={{ maxWidth: '100%' }}
            value={value}
            onChange={inputChangeHandler}
            onKeyDown={inputKeyDownHandler}
          />
        </div>
      </div>
      {value !== '' && (
        <div className="col-auto">
          <button
            className="btn-close me-2"
            type="button"
            onClick={resetButtonClickHandler}
            aria-label="Reset"
          />
        </div>
      )}
      {props.searchButton && (
        <div className="col-auto m-0 p-0">
          <button
            className={`btn btn-primary rounded-0 ${styles['search-button']}`}
            type="button"
            onClick={searchButtonClickHandler}
          >
            Search
          </button>
        </div>
      )}
    </div>
    //* NEW CODE
    // <div className="hstack align-items-stretch position-relative border rounded bg-white overflow-hidden">
    //   <input
    //     className="flex-grow-1 h-100 p-2"
    //     style={{ minWidth: '0', borderStyle: 'none', outlineStyle: 'none' }}
    //     value={value}
    //     onChange={inputChangeHandler}
    //     onKeyDown={inputKeyDownHandler}
    //   />
    //   <button
    //     className="btn-close align-self-center me-2"
    //     style={{ minWidth: '16px' }}
    //     type="button"
    //     aria-label="Reset"
    //   />
    //   <button
    //     className="btn btn-primary rounded-0 rounded-end user-select-none"
    //     type="button"
    //   >
    //     <span
    //       className="material-symbols-rounded d-sm-none"
    //       style={{
    //         fontSize: '1.2em',
    //         verticalAlign: '-21%',
    //         fontVariationSettings: `'FILL' 1, 'wght' 500, 'GRAD' 0, 'opsz' 48`,
    //       }}
    //       aria-hidden="true"
    //     >
    //       search
    //     </span>
    //     <span className="d-none d-sm-inline">Search</span>
    //   </button>
    // </div>
  );
};

export default SearchBar;

import React from 'react';

const Footer = (props) => {
  return (
    <footer className="border-top border-dark-subtle bg-body-secondary bg-gradient text-body-secondary">
      <div className="container-xl p-3 text-center">All rights reserved.</div>
    </footer>
  );
};

export default Footer;

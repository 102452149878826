import React, { useEffect, useRef, useState } from 'react';
import {
  Link,
  useLoaderData,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';
import MasterPage from '../../../components/MasterPage';
import List from '../../../components/List';
import Toast from '../../../components/Toast';
import { prepareParams } from '../../../util';

const breadcrumb = [{ label: 'Programs' }];

const defaultPageSize = 10;
const defaultSortField = 'name';
const allowedSizes = [5, 10, 20];

const fields = [
  { name: 'code', label: 'Code', getValue: (item) => item.id },
  {
    name: 'name',
    label: 'Name',
    getValue: (item) => item.name,
  },
  { name: 'category', label: 'Category', getValue: (item) => item.category },
  // {
  //   name: 'status',
  //   label: 'Status',
  //   getValue: (item) => (
  //     <span className="text-nowrap">
  //       <span
  //         className={`badge p-1 me-2 ${
  //           item.status === 'Enabled' ? 'bg-success' : 'bg-danger'
  //         } border rounded-circle`}
  //       >
  //         <span className="visually-hidden">{item.email}</span>
  //       </span>
  //       {item.status}
  //     </span>
  //   ),
  // },
];

const Main = (props) => {
  const navigate = useNavigate();

  // const [{ roles }, { totalCount, members }] = useLoaderData();
  const [{ totalCount, programs }] = useLoaderData();

  const [items, setItems] = useState(
    programs.map((program) => ({
      id: program.id,
      data: program,
      selected: false,
    }))
  );

  const toastRef = useRef(null);

  // const roleFilterRef = useRef(null);
  // const statusFilterRef = useRef(null);

  const [searchParams, setSearchParams] = useSearchParams();
  const pageParam = searchParams.get('page');
  const sizeParam = searchParams.get('size');
  const sortParam = searchParams.get('sort');
  const directionParam = searchParams.get('direction');
  const searchParam = searchParams.get('search');
  // const roleParam = searchParams.getAll('role');
  // const statusParam = searchParams.getAll('status');
  const currentParams = {
    page: pageParam ? parseInt(pageParam, 10) : 1,
    size: sizeParam ? parseInt(sizeParam, 10) : defaultPageSize,
    sort: sortParam ? sortParam : defaultSortField,
    direction: directionParam ? directionParam : 'asc',
    search: searchParam ? searchParam : null,
    // role: roleParam.map((r) => parseInt(r, 10)),
    // status: statusParam,
  };

  const filters = [
    // {
    //   label: 'Role',
    //   type: 'checkbox',
    //   param: 'role',
    //   ref: roleFilterRef,
    //   options: roles.map((role) => ({
    //     label: role.name,
    //     value: role.id,
    //     selected: currentParams.role.includes(role.id),
    //   })),
    // },
    // {
    //   label: 'Status',
    //   type: 'checkbox',
    //   param: 'status',
    //   ref: statusFilterRef,
    //   options: [
    //     {
    //       label: 'Enabled',
    //       value: 'enabled',
    //       selected: currentParams.status.includes('enabled'),
    //     },
    //     {
    //       label: 'Disabled',
    //       value: 'disabled',
    //       selected: currentParams.status.includes('disabled'),
    //     },
    //   ],
    // },
  ];

  const bulkActions = [
    // {
    //   label: 'Enable',
    //   onClick: async () => {
    //     const results = await Promise.all(
    //       items
    //         .filter((item) => item.selected)
    //         .map((item) =>
    //           fetch(`/api/members/${item.id}`, {
    //             method: 'PATCH',
    //             headers: {
    //               'Content-Type': 'application/json',
    //             },
    //             body: JSON.stringify({ isEnabled: true }),
    //           })
    //         )
    //     );
    //     if (results.every((result) => result.ok)) {
    //       toastRef.current.show(
    //         'Members enabled successfully!',
    //         'success',
    //         () => {
    //           setSearchParams(
    //             prepareParams(currentParams, {
    //               page: 1,
    //             })
    //           );
    //         }
    //       );
    //       return;
    //     }
    //     toastRef.current.show(
    //       'Members enable failed! Please try again.',
    //       'danger'
    //     );
    //   },
    // },
    // {
    //   label: 'Disable',
    //   onClick: async () => {
    //     const results = await Promise.all(
    //       items
    //         .filter((item) => item.selected)
    //         .map((item) =>
    //           fetch(`/api/members/${item.id}`, {
    //             method: 'PATCH',
    //             headers: {
    //               'Content-Type': 'application/json',
    //             },
    //             body: JSON.stringify({ isEnabled: false }),
    //           })
    //         )
    //     );
    //     if (results.every((result) => result.ok)) {
    //       toastRef.current.show(
    //         'Members disabled successfully!',
    //         'success',
    //         () => {
    //           setSearchParams(
    //             prepareParams(currentParams, {
    //               page: 1,
    //             })
    //           );
    //         }
    //       );
    //       return;
    //     }
    //     toastRef.current.show(
    //       'Members disable failed! Please try again.',
    //       'danger'
    //     );
    //   },
    // },
  ];

  const actions = [
    {
      label: 'View',
      // icon: 'visibility',
      onClick: (id) => {
        navigate(`/programs/${id}`);
      },
    },
    {
      label: 'Edit',
      // icon: 'edit',
      onClick: (id) => {
        navigate(`/programs/${id}/edit`);
      },
    },
  ];

  useEffect(() => {
    setItems(
      programs.map((event) => ({
        id: event.id,
        data: event,
        selected: false,
      }))
    );
  }, [programs]);

  function searchChangeHandler(searchString) {
    setSearchParams(
      prepareParams(currentParams, {
        search: searchString ? searchString : null,
        page: 1,
      })
    );
  }

  function filtersChangeHandler(filters) {
    console.log(filters);
    setSearchParams(
      prepareParams(
        currentParams,
        filters.reduce(
          (filterParams, filter) => {
            filterParams[filter.param] = filter.values;
            return filterParams;
          },
          { page: 1 }
        )
      )
    );
  }

  function sortChangeHandler(sort, direction) {
    if (sort !== currentParams.sort || direction !== currentParams.direction) {
      setSearchParams(
        prepareParams(currentParams, {
          sort,
          direction,
        })
      );
    }
  }

  function paginationChangeHandler(page, size) {
    setSearchParams(prepareParams(currentParams, { page, size }));
  }

  function itemSelectionChangeHandler(id, value) {
    setItems((previous) =>
      previous.map((item) =>
        item.id === id ? { ...item, selected: value } : item
      )
    );
  }

  function allItemsSelectionChangeHandler(value) {
    setItems((previous) =>
      previous.map((item) => ({ ...item, selected: value }))
    );
  }

  return (
    <MasterPage breadcrumb={breadcrumb}>
      <div className="vstack gap-3 m-3">
        <div className="hstack justify-content-md-center align-items-center position-relative">
          <h1 className="m-0">Programs</h1>
          <div
            className={
              'hstack justify-content-end align-items-center ' +
              'position-absolute w-100 h-100 pe-none'
            }
          >
            <Link className="btn btn-primary pe-auto" to="/programs/new">
              <span
                className="material-symbols-rounded me-1 user-select-none"
                style={{
                  fontSize: '1.2em',
                  verticalAlign: '-21%',
                  fontVariationSettings: `'FILL' 1, 'wght' 500, 'GRAD' 0, 'opsz' 48`,
                }}
                aria-hidden="true"
              >
                add
              </span>
              Add Program
            </Link>
          </div>
        </div>
        <List
          className=""
          items={items}
          fields={fields}
          actions={actions}
          bulkActions={bulkActions}
          search={true}
          searchButton={true}
          searchValue={currentParams.search || ''}
          onSearch={searchChangeHandler}
          // onSearchChange={(value) => {
          //   console.log(value);
          // }}
          filters={filters}
          onFilterChange={filtersChangeHandler}
          selection={false}
          onItemSelectionChange={itemSelectionChangeHandler}
          onAllItemsSelectionChange={allItemsSelectionChangeHandler}
          sort={true}
          sortField={currentParams.sort}
          sortDirection={currentParams.direction}
          onSortChange={sortChangeHandler}
          pagination={true}
          page={currentParams.page}
          size={currentParams.size}
          totalCount={totalCount}
          allowedSizes={allowedSizes}
          onPaginationChange={paginationChangeHandler}
          itemLabel="program"
          itemLabelPlural="programs"
          breakpoint="md"
          itemsContainerMinHeight="200px"
        />
      </div>
      <Toast ref={toastRef} />
    </MasterPage>
  );
};

export default Main;

import React, { useRef } from 'react';
import { Link, useLoaderData, useNavigate } from 'react-router-dom';
import MasterPage from '../../../components/MasterPage';
import Form from '../../../components/Form';
import Toast from '../../../components/Toast';

const emailRegex = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;

const breadcrumb = [{ label: 'Profile', path: '/profile' }, { label: 'Edit' }];

const Edit = () => {
  const navigate = useNavigate();

  const [{ member }] = useLoaderData();

  const formRef = useRef(null);
  const codeRef = useRef(null);
  const firstNameRef = useRef(null);
  const lastNameRef = useRef(null);
  const emailRef = useRef(null);
  const phonesRef = useRef(null);

  const toastRef = useRef(null);

  const items = [
    {
      id: 'code',
      ref: codeRef,
      name: 'code',
      label: 'Code',
      type: 'text',
      value: member.code,
      size: { xs: 12, sm: '6', md: '4' },
      disabled: true,
      validationDisabled: true,
    },
    {
      id: 'firstName',
      ref: firstNameRef,
      name: 'firstName',
      label: 'First Name',
      type: 'text',
      value: member.firstName,
      size: { xs: 12 },
      required: true,
      validations: [
        {
          validate: (value) => value.length > 0,
          message: 'First name is required.',
        },
      ],
    },
    {
      id: 'lastName',
      ref: lastNameRef,
      name: 'lastName',
      label: 'Last Name',
      type: 'text',
      value: member.lastName,
      size: { xs: 12 },
      required: true,
      validations: [
        {
          validate: (value) => value.length > 0,
          message: 'Last name is required.',
        },
      ],
    },
    {
      id: 'email',
      ref: emailRef,
      name: 'email',
      label: 'Email',
      type: 'email',
      value: member.email,
      size: { xs: 12 },
      required: true,
      validations: [
        {
          validate: (value) => value.length > 0,
          message: 'Email is required.',
        },
        {
          validate: (value) => value === '' || emailRegex.test(value),
          message: 'Must be a valid email.',
        },
        {
          validate: (value) =>
            value.length === 0
              ? true
              : new Promise((resolve, reject) =>
                  fetch('/api/members/validate-email', {
                    method: 'POST',
                    headers: {
                      'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                      code: member.code,
                      email: emailRef.current.getValue(),
                    }),
                  }).then((result) =>
                    result.json().then((data) => {
                      resolve(data.isValid);
                    })
                  )
                ),
          message: 'This email is already in use.',
        },
      ],
    },
    {
      id: 'phones',
      ref: phonesRef,
      name: 'phones',
      label: 'Phones',
      type: 'text',
      value: member.phones,
      size: { xs: 12, sm: '8', md: '6' },
    },
  ];

  const actions = [
    {
      label: 'Save',
      type: 'submit',
      variant: 'primary',
    },
    {
      label: 'Cancel',
      type: 'button',
      variant: 'secondary',
      onClick: () => {
        navigate('/profile');
      },
    },
  ];

  async function submitHandler(event) {
    event.preventDefault();
    const isValid = await formRef.current.validate();
    if (!isValid) {
      toastRef.current.show(
        'Member update failed! Please check for errors.',
        'danger'
      );
      return;
    }
    const data = formRef.current.getData();
    const result = await fetch(`/api/members/${member.code}`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });
    if (result.ok) {
      toastRef.current.show('Member updated successfully!', 'success', () => {
        formRef.current.reset();
      });
      return;
    }
    toastRef.current.show('Member update failed! Please try again.', 'danger');
  }

  return (
    <MasterPage breadcrumb={breadcrumb} title="Edit Profile">
      <div
        className="row justify-content-end"
        style={{ width: 'calc(min(100%, 800px))' }}
      >
        <div className="col-12 col-sm-auto px-0">
          <Link className="btn btn-primary w-100" to="/profile/change-password">
            Change Password
          </Link>
        </div>
      </div>
      <div
        className="border rounded bg-white p-3"
        style={{ width: 'calc(min(100%, 800px))' }}
      >
        <Form
          ref={formRef}
          id="profile-form"
          items={items}
          actions={actions}
          layoutBreakpoint="sm"
          actionsBreakpoint="sm"
          labelContainerSize={{ sm: 2 }}
          onSubmit={submitHandler}
        />
      </div>
      <Toast ref={toastRef} />
    </MasterPage>
  );
};

export default Edit;

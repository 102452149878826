import React from 'react';

const FilterPill = (props) => {
  function closeButtonClickHandler() {
    props.onRemove(props.param, props.value);
  }

  return (
    <div
      className="hstack h-100 text-bg-dark rounded"
      style={{ maxWidth: '100%' }}
    >
      <div
        className="flex-grow-1 text-nowrap overflow-hidden text-center"
        style={{ textOverflow: 'ellipsis' }}
      >
        <span
          className="ps-3 me-1"
          style={{ color: 'var(--bs-secondary-bg)' }}
        >{`${props.filter}:`}</span>
        <span className="pe-3 cursor-default fw-bold">{props.label}</span>
      </div>
      <div className="vr"></div>
      <button
        className="btn btn-dark border-0 rounded-0 rounded-end"
        type="button"
        onClick={closeButtonClickHandler}
      >
        <span
          className="material-symbols-rounded user-select-none"
          style={{
            fontSize: '1.3em',
            verticalAlign: '-20%',
            fontVariationSettings: "'FILL' 1, 'wght' 600, 'GRAD' 0, 'opsz' 48",
          }}
          aria-hidden="true"
        >
          close
        </span>
      </button>
    </div>
  );
};

export default FilterPill;

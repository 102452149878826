function formatTwoDigits(number) {
  return number < 10 ? `0${number}` : number;
}

export function localToUtc(date, time) {
  const datetime = new Date(`${date}T${time}`);

  const year = datetime.getUTCFullYear();
  const month = formatTwoDigits(datetime.getUTCMonth() + 1);
  const day = formatTwoDigits(datetime.getUTCDate());
  const hours = formatTwoDigits(datetime.getUTCHours());
  const minutes = formatTwoDigits(datetime.getUTCMinutes());

  const utcDate = `${year}-${month}-${day}`;
  const utcTime = `${hours}:${minutes}:00`;

  return [utcDate, utcTime];
}

export function utcToLocal(date, time) {
  const datetime = new Date(`${date}T${time}Z`);

  const year = datetime.getFullYear();
  const month = formatTwoDigits(datetime.getMonth() + 1);
  const day = formatTwoDigits(datetime.getDate());
  const hours = formatTwoDigits(datetime.getHours());
  const minutes = formatTwoDigits(datetime.getMinutes());

  const localDate = `${year}-${month}-${day}`;
  const localTime = `${hours}:${minutes}`;

  return [localDate, localTime];
}

import React, {
  forwardRef,
  useEffect,
  useId,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react';
import styles from './index.module.css';

const FilterVenue = forwardRef((props, ref) => {
  const [searchValue, setSearchValue] = useState('');

  const trimmedSearchValue = searchValue.trim().toLowerCase();

  const filteredOptions = useMemo(
    () =>
      props.options.filter((option) =>
        option.label.toLowerCase().includes(trimmedSearchValue)
      ),
    [props.options, trimmedSearchValue]
  );

  const [value, setValue] = useState(props.value);

  useImperativeHandle(ref, () => ({
    getActiveFilters: () => [
      { param: props.param, values: value ? [value] : [] },
      // { param: props.afterParam, values: after ? [after] : [] },
    ],
  }));

  useEffect(() => {
    setValue(props.value);
  }, [props.value]);

  return (
    <div
      className="dropdown-menu p-3 w-100 w-sm-auto"
      style={{ minWidth: 'calc(min(300px, 100vw))' }}
    >
      <div
        className="hstack border rounded overflow-hidden text-body-secondary"
        style={{ minHeight: '33.6px' }}
      >
        <span
          className="material-symbols-rounded user-select-none ms-2"
          style={{
            fontSize: '1.2em',
            verticalAlign: '-21%',
            fontVariationSettings: `'FILL' 1, 'wght' 500, 'GRAD' 0, 'opsz' 48`,
          }}
          aria-hidden="true"
        >
          search
        </span>
        <input
          className="px-2 py-1"
          style={{
            minWidth: '0',
            borderStyle: 'none',
            outlineStyle: 'none',
          }}
          type="text"
          value={searchValue}
          onChange={(event) => setSearchValue(event.target.value)}
        />
      </div>
      <div
        className={`vstack gap-1 mt-2 ${styles['options-container']}`}
        style={{ maxHeight: '26vh', overflowY: 'auto' }}
      >
        {filteredOptions.map((option) => (
          <div
            key={option.value}
            className={`border rounded py-2 px-3 text-nowrap overflow-hidden ${
              option.value === value
                ? 'border-primary bg-primary bg-opacity-10 text-black'
                : ''
            } ${styles.option}`}
            style={{
              cursor: 'pointer',
              textOverflow: 'ellipsis',
              minHeight: '41.6px',
            }}
            data-tooltip-id="venue-tooltip"
            data-tooltip-content={option.label}
            onClick={(event) => {
              event.stopPropagation();
              setValue(option.value);
            }}
          >
            {option.label}
          </div>
        ))}
      </div>
    </div>
  );
});

export default FilterVenue;

import React from 'react';

const FormTitle = (props) => {
  return (
    <div className="hstack justify-content-center">
      <span className="fs-4">{props.title}</span>
    </div>
  );
};

export default FormTitle;

import { redirect } from 'react-router-dom';
import Home from '../pages/Home';
import Login from '../pages/Login';
import membersRoutes from './members';
import eventsRoutes from './events';
import programsRoutes from './programs';
import servicesRoutes from './services';
import venuesRoutes from './venues';
import reportsRoutes from './reports';
import profileRoutes from './profile';
import Main from '../pages/Profile/Main';
import ChangePassword from '../pages/ChangePassword';

const routesBase = [
  {
    path: '/',
    element: <Home />,
    loader: ({ params, request }) => {
      return null;
    },
  },
  {
    path: '/login',
    element: <Login />,
    loader: ({ params, request }) => {
      return null;
    },
  },
  {
    path: '/change-password',
    element: <ChangePassword />,
    loader: ({ params, request }) => {
      return null;
    },
  },
  ...profileRoutes,
  ...membersRoutes,
  ...eventsRoutes,
  ...programsRoutes,
  ...servicesRoutes,
  ...venuesRoutes,
  ...reportsRoutes,
];

const routes = routesBase.map((route) => {
  const { path } = route;
  if (path === '/login') {
    return route;
  }
  const loader = (params, request) => {
    const code = sessionStorage.getItem('code');
    const role = sessionStorage.getItem('role');
    if (code === null) {
      return redirect('/login');
    }
    if (route.roles && !route.roles.includes(role)) {
      return redirect('/');
    }
    if (route.loader) {
      return route.loader(params, request);
    }
  };
  return { ...route, loader };
});

export default routes;

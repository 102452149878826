import React, { forwardRef, useImperativeHandle, useRef } from 'react';
import styles from './index.module.css';

const Input = forwardRef((props, ref) => {
  const { isValid, wasValidated, width, disabled, children, ...innerProps } =
    props;
  const inputRef = useRef(null);

  useImperativeHandle(ref, () => ({
    focus: () => {
      inputRef.current.focus();
    },
  }));

  function containerClickHandler(event) {
    if (event.target !== inputRef.current) {
      inputRef.current.focus();
    }
  }

  return (
    <div
      className={`border rounded bg-${
        disabled ? 'body-tertiary' : 'white'
      } hstack gap-2 ${styles.container} ${
        wasValidated ? (isValid ? styles.valid : styles.invalid) : ''
      }`}
      onClick={containerClickHandler}
      style={{ width: width ? width : '100%' }}
    >
      <input
        ref={inputRef}
        className={`flex-grow-1 text-${disabled ? 'body-secondary' : 'dark'} ${
          styles.input
        }`}
        disabled={disabled}
        {...innerProps}
      />
      {children}
      {wasValidated && (
        <span
          className={`material-symbols-rounded text-${
            isValid ? 'success' : 'danger'
          } user-select-none ${styles.icon}`}
          aria-hidden="true"
        >
          {isValid ? 'check_circle' : 'error'}
        </span>
      )}
    </div>
  );
});

export default Input;

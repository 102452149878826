import React, { useMemo } from 'react';
import SearchBar from './components/SearchBar';
import Filters from './components/Filters';
import ItemsContainer from './components/ItemsContainer';
import Pagination from './components/Pagination';

const List = (props) => {
  const selectedCount = useMemo(
    () => props.items.filter((item) => item.selected).length,
    [props.items]
  );

  return (
    <div className={`${props.className || ''} vstack gap-1`}>
      {/* <SearchBar />
      <div className="bg-warning">filters</div>
      <div className="bg-danger">bulk actions</div>
      <ItemsContainer items={props.items} fields={props.fields} />
      <div className="bg-success">pagination</div> */}

      {props.search && (
        <SearchBar
          searchButton={props.searchButton}
          value={props.searchValue}
          onSearch={props.onSearch}
          onSearchChange={props.onSearchChange}
        />
      )}
      {Array.isArray(props.filters) && props.filters.length > 0 && (
        <Filters filters={props.filters} onChange={props.onFilterChange} />
      )}
      {props.selection && (
        <div className="hstack gap-2 justify-content-between">
          <span>
            <span>{selectedCount}</span>{' '}
            <span className="d-none d-md-inline">
              {selectedCount === 1 ? props.itemLabel : props.itemLabelPlural}
            </span>{' '}
            <span>selected</span>
          </span>
          {props.bulkActions.length > 0 && (
            <div className="hstack gap-2">
              {props.bulkActions.map((action, index) => (
                <button
                  key={index}
                  className="btn btn-primary"
                  type="button"
                  onClick={action.onClick}
                  disabled={selectedCount === 0}
                >
                  {action.label}
                </button>
              ))}
            </div>
          )}
        </div>
      )}
      <ItemsContainer
        items={props.items}
        fields={props.fields}
        actions={props.actions}
        selection={props.selection}
        onItemSelectionChange={props.onItemSelectionChange}
        onAllItemsSelectionChange={props.onAllItemsSelectionChange}
        sort={props.sort}
        sortField={props.sortField}
        sortDirection={props.sortDirection}
        onSortChange={props.onSortChange}
        height={props.itemsContainerHeight}
        minHeight={props.itemsContainerMinHeight}
        itemLabel={props.itemLabel}
        itemLabelPlural={props.itemLabelPlural}
      />
      {props.pagination && (
        <Pagination
          page={props.page}
          size={props.size}
          allowedSizes={props.allowedSizes}
          itemCount={props.items.length}
          totalCount={props.totalCount}
          itemLabel={props.itemLabel}
          itemLabelPlural={props.itemLabelPlural}
          onPaginationChange={props.onPaginationChange}
          hideSummary={props.hideSummary}
        />
      )}
    </div>
  );
};

export default List;

import React, { Fragment } from 'react';
import BreadcrumbItem from './components/BreadcrumbItem';

const Breadcrumb = (props) => {
  const items = [
    {
      label: (
        <Fragment>
          <span
            className="material-symbols-rounded user-select-none"
            style={{
              fontVariationSettings: `'FILL' 1, 'wght' 400, 'GRAD' 0, 'opsz' 48`,
              fontSize: '1.2em',
              verticalAlign: '-16%',
            }}
            aria-hidden="true"
          >
            home
          </span>
          Home
        </Fragment>
      ),
      path: '/',
    },
    ...props.items,
  ];
  const lastIndex = items.length - 1;

  return (
    <nav
      className="px-3 py-1 border-bottom border-dark-subtle"
      style={{
        '--bs-breadcrumb-divider': 'none',
        textUnderlineOffset: '1.75px',
      }}
      aria-label="Breadcrumb"
    >
      <ol className="breadcrumb m-0">
        {items.map((item, index) => (
          <BreadcrumbItem
            key={index}
            label={item.label}
            path={item.path}
            first={index === 0}
            current={index === lastIndex}
          />
        ))}
      </ol>
    </nav>
  );
};

export default Breadcrumb;

import React, { useRef, useState } from 'react';
import { useLoaderData, useNavigate } from 'react-router-dom';
import MasterPage from '../../../components/MasterPage';
import Form from '../../../components/Form';
import Toast from '../../../components/Toast';

const emailRegex = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;

const Create = (props) => {
  const navigate = useNavigate();

  const [{ member: memberBase }, { roles }, { civilStatuses }] =
    useLoaderData();

  const [member, setMember] = useState(memberBase);

  const breadcrumb = [
    { label: 'Members', path: '/members' },
    { label: member.code, path: `/members/${member.code}` },
    { label: 'Edit' },
  ];

  const toastRef = useRef(null);

  const formRef = useRef(null);
  const codeRef = useRef(null);
  const firstNameRef = useRef(null);
  const lastNameRef = useRef(null);
  const emailRef = useRef(null);
  const roleRef = useRef(null);
  const phonesRef = useRef(null);
  const genderRef = useRef(null);
  const birthDateRef = useRef(null);
  const civilStatusRef = useRef(null);
  const educationRef = useRef(null);
  const occupationRef = useRef(null);
  const hobbiesRef = useRef(null);
  const workScheduleRef = useRef(null);
  const medicalConditionsRef = useRef(null);
  const dietRestrictionsRef = useRef(null);
  const foodAllergiesRef = useRef(null);
  const activeLaicRef = useRef(null);
  const enabledRef = useRef(null);

  const formProps = {
    id: 'member-form',
    className: 'border rounded px-3 py-3 py-md-5',
    ref: formRef,
    labelContainerSize: { md: 3 },
    items: [
      {
        id: 'code',
        ref: codeRef,
        name: 'code',
        label: 'Code',
        type: 'text',
        value: `${member.code}`,
        size: { xs: 12, md: '6' },
        disabled: true,
        validationDisabled: true,
      },
      {
        id: 'role',
        ref: roleRef,
        name: 'roleId',
        label: 'Role',
        type: 'select',
        options: roles.map((role) => ({
          label: role.name,
          value: `${role.id}`,
        })),
        value: `${member.roleId}`,
        size: { xs: 12, md: '6' },
        required: true,
        validations: [
          {
            validate: (value) => value.length > 0,
            message: 'Role is required.',
          },
        ],
      },
      {
        id: 'first-name',
        ref: firstNameRef,
        name: 'firstName',
        label: 'First Name',
        type: 'text',
        value: member.firstName,
        size: { xs: 12 },
        required: true,
        validations: [
          {
            validate: (value) => value.length > 0,
            message: 'First name is required.',
          },
        ],
      },
      {
        id: 'last-name',
        ref: lastNameRef,
        name: 'lastName',
        label: 'Last Name',
        type: 'text',
        value: member.lastName,
        size: { xs: 12 },
        required: true,
        validations: [
          {
            validate: (value) => value.length > 0,
            message: 'Last name is required.',
          },
        ],
      },
      {
        id: 'email',
        ref: emailRef,
        name: 'email',
        label: 'Email',
        type: 'email',
        value: member.email,
        size: { xs: 12 },
        required: true,
        validations: [
          {
            validate: (value) => value.length > 0,
            message: 'Email is required.',
          },
          {
            validate: (value) => value === '' || emailRegex.test(value),
            message: 'Must be a valid email.',
          },
          {
            validate: (value) =>
              value.length === 0
                ? true
                : new Promise((resolve, reject) =>
                    fetch('/api/members/validate-email', {
                      method: 'POST',
                      headers: {
                        'Content-Type': 'application/json',
                      },
                      body: JSON.stringify({
                        code: member.code,
                        email: emailRef.current.getValue(),
                      }),
                    }).then((result) =>
                      result.json().then((data) => {
                        resolve(data.isValid);
                      })
                    )
                  ),
            message: 'This email is already in use.',
          },
        ],
      },
      {
        id: 'phones',
        ref: phonesRef,
        name: 'phones',
        label: 'Phones',
        type: 'text',
        value: member.phones,
        size: { xs: 12 },
      },
      {
        id: 'gender',
        ref: genderRef,
        name: 'gender',
        label: 'Gender',
        type: 'select',
        options: [
          { label: 'Male', value: 'Male' },
          { label: 'Female', value: 'Female' },
        ],
        value: member.gender,
        size: { xs: 12, md: '6' },
      },
      {
        id: 'birth-date',
        ref: birthDateRef,
        name: 'birthDate',
        label: 'Birth Date',
        type: 'date',
        value: member.birthDate,
        size: { xs: 12, md: '6' },
      },
      {
        id: 'civil-status',
        ref: civilStatusRef,
        name: 'civilStatusId',
        label: 'Civil Status',
        type: 'select',
        options: civilStatuses.map((civilStatus) => ({
          label: civilStatus.name,
          value: `${civilStatus.id}`,
        })),
        value: `${member.civilStatusId}`,
        size: { xs: 12, md: '6' },
      },
      {
        id: 'education',
        ref: educationRef,
        name: 'education',
        label: 'Education',
        type: 'area',
        value: member.education,
        size: { xs: 12 },
      },
      {
        id: 'occupation',
        ref: occupationRef,
        name: 'occupation',
        label: 'Occupation',
        type: 'area',
        value: member.occupation,
        size: { xs: 12 },
      },
      {
        id: 'work-schedule',
        ref: workScheduleRef,
        name: 'workSchedule',
        label: 'Work Schedule',
        type: 'area',
        value: member.workSchedule,
        size: { xs: 12 },
      },
      {
        id: 'hobbies',
        ref: hobbiesRef,
        label: 'Hobbies',
        name: 'hobbies',
        type: 'area',
        value: member.hobbies,
        size: { xs: 12 },
      },
      {
        id: 'medical-conditions',
        ref: medicalConditionsRef,
        name: 'medicalConditions',
        label: 'Medical Conditions',
        type: 'area',
        value: member.medicalConditions,
        size: { xs: 12 },
      },
      {
        id: 'diet-restrictions',
        ref: dietRestrictionsRef,
        name: 'dietRestrictions',
        label: 'Diet Restrictions',
        type: 'area',
        value: member.dietRestrictions,
        size: { xs: 12 },
      },
      {
        id: 'food-allergies',
        ref: foodAllergiesRef,
        name: 'foodAllergies',
        label: 'Food Allergies',
        type: 'area',
        value: member.foodAllergies,
        size: { xs: 12 },
      },
      {
        id: 'active-laic',
        ref: activeLaicRef,
        name: 'isActiveLaic',
        label: 'Active Laic',
        type: 'switch',
        value: member.isActiveLaic,
        size: { xs: 12 },
      },
      {
        id: 'enabled',
        ref: enabledRef,
        name: 'isEnabled',
        label: 'Enabled',
        type: 'switch',
        value: member.isEnabled,
        size: { xs: 12 },
      },
    ],
    actions: [
      {
        label: 'Save',
        type: 'submit',
        variant: 'primary',
      },
      {
        label: 'Cancel',
        type: 'button',
        variant: 'secondary',
        onClick: () => {
          navigate(`/members/${member.code}`);
        },
      },
    ],
    maxWidth: '800px',
    layoutBreakpoint: 'md',
    actionsBreakpoint: 'sm',
    onSubmit: async (event) => {
      event.preventDefault();
      const isValid = await formRef.current.validate();
      if (!isValid) {
        toastRef.current.show(
          'Member update failed! Please check for errors.',
          'danger'
        );
        return;
      }
      const data = formRef.current.getData();
      const result = await fetch(`/api/members/${member.code}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });
      if (result.ok) {
        setMember(data);
        toastRef.current.show('Member updated successfully!', 'success', () => {
          formRef.current.reset();
        });
        return;
      }
      toastRef.current.show(
        'Member update failed! Please try again.',
        'danger'
      );
    },
  };

  return (
    <MasterPage breadcrumb={breadcrumb}>
      <div className="vstack gap-3 m-3" style={{}}>
        <div className="hstack justify-content-md-center align-items-center position-relative">
          <h1>Edit Member</h1>
        </div>
        <div className="hstack justify-content-center">
          <Form {...formProps} />
        </div>
      </div>
      <Toast ref={toastRef} />
    </MasterPage>
  );
};

export default Create;

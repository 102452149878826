import React, { forwardRef, useImperativeHandle } from 'react';
import FormActions from './components/FormActions';
import FormItems from './components/FormItems';
import FormTitle from './components/FormTitle';

const Form = forwardRef((props, ref) => {
  const {
    children,
    id,
    layoutBreakpoint,
    className,
    maxWidth,
    title,
    items,
    labelContainerSize,
    actions,
    actionsBreakpoint,
    validationDisabled,
    ...innerProps
  } = props;

  useImperativeHandle(ref, () => ({
    getData: () => {
      const data = {};
      props.items.forEach((item) => {
        data[item.name] = item.ref.current.getValue();
      });
      return data;
    },
    validate: async () => {
      const results = await Promise.all(
        props.items.map((item) => item.ref.current.validate())
      );
      return results.every((result) => result);
    },
    reset: () => {
      props.items.forEach((item) => item.ref.current.reset());
    },
  }));

  return (
    <form
      id={id}
      className={`${className ? className : ''} flex-grow-1`}
      style={{ maxWidth: maxWidth ? maxWidth : '100%' }}
      {...innerProps}
    >
      {children}
      <div className="row">
        <div
          className={
            layoutBreakpoint
              ? `offset-${layoutBreakpoint}-1 col-12 col-${layoutBreakpoint}-10`
              : 'col-12'
          }
        >
          <div className="vstack gap-4">
            {title && <FormTitle title={title} />}
            <FormItems
              formId={id}
              items={items}
              breakpoint={layoutBreakpoint}
              labelContainerSize={labelContainerSize}
              validationDisabled={validationDisabled}
            />
            <FormActions actions={actions} breakpoint={actionsBreakpoint} />
          </div>
        </div>
      </div>
    </form>
  );
});

export default Form;

import React, { useState, useEffect } from 'react';
import PaginationItem from './components/PaginationItem';

const pageRegex = /^[0-9]*$/;

const PaginationNavigation = (props) => {
  const lastPage = Math.max(Math.ceil(props.totalCount / props.size), 1);
  const currentPage = `${props.page}`;
  const [inputPage, setInputPage] = useState(currentPage);

  useEffect(() => {
    setInputPage(`${props.page}`);
  }, [props.page]);

  function firstClickHandler(event) {
    event.preventDefault();
    props.onPaginationChange(1, props.size);
  }

  function previousClickHandler(event) {
    event.preventDefault();
    props.onPaginationChange(props.page - 1, props.size);
  }

  function nextClickHandler(event) {
    event.preventDefault();
    props.onPaginationChange(props.page + 1, props.size);
  }

  function lastClickHandler(event) {
    event.preventDefault();
    props.onPaginationChange(lastPage, props.size);
  }

  function updateCurrentPage() {
    if (inputPage === currentPage) {
      return;
    }
    if (inputPage === '') {
      setInputPage(currentPage);
      return;
    }
    const page = parseInt(inputPage, 10);
    if (page < 1) {
      setInputPage(1);
      props.onPaginationChange(1, props.size);
      return;
    }
    if (page > lastPage) {
      setInputPage(lastPage);
      props.onPaginationChange(lastPage, props.size);
      return;
    }
    props.onPaginationChange(page, props.size);
  }

  function pageInputChangeHandler(event) {
    const { value } = event.target;
    if (pageRegex.test(value)) {
      setInputPage(event.target.value);
    }
  }

  function pageInputKeyDownHandler(event) {
    if (event.key === 'Enter') {
      updateCurrentPage();
    }
  }

  function pageInputBlurHandler() {
    updateCurrentPage();
  }

  return (
    <ul className="pagination m-0 d-flex gap-2">
      <PaginationItem disabled={props.page === 1} onClick={firstClickHandler}>
        <i className="bi bi-chevron-bar-left" aria-hidden="true" />
        <span className="d-none d-lg-inline"> First</span>
      </PaginationItem>
      <PaginationItem
        disabled={props.page === 1}
        onClick={previousClickHandler}
      >
        <i className="bi bi-chevron-left" aria-hidden="true" />
        <span className="d-none d-md-inline"> Previous</span>
      </PaginationItem>
      <li className="page-item">
        <div className="d-flex gap-2 align-items-center">
          <input
            className="form-control text-center px-0"
            style={{
              width: `${Math.max(Math.floor(Math.log10(lastPage)) + 2, 4)}em`,
            }}
            value={inputPage}
            onChange={pageInputChangeHandler}
            onKeyDown={pageInputKeyDownHandler}
            onBlur={pageInputBlurHandler}
          />
          <span className="d-none d-md-inline">of {lastPage}</span>
        </div>
      </li>
      <PaginationItem
        disabled={props.page === lastPage}
        onClick={nextClickHandler}
      >
        <span className="d-none d-md-inline">Next </span>
        <i className="bi bi-chevron-right" aria-hidden="true" />
      </PaginationItem>
      <PaginationItem
        disabled={props.page === lastPage}
        onClick={lastClickHandler}
      >
        <span className="d-none d-lg-inline">Last </span>
        <i className="bi bi-chevron-bar-right" aria-hidden="true" />
      </PaginationItem>
    </ul>
  );
};

export default PaginationNavigation;
